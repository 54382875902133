.InnerBlog .row {
  width: 100% !important;
  height: auto;
  margin: 0 !important;
}
.blog-detail {
  padding: calc(44/ var(--rem)) calc(15/ var(--rem)) calc(172/ var(--rem));
  max-width: calc(1070/ var(--rem));
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
}
.blog-detail .LeftPartBlog{
  width: 26.2%;
}
.blog-detail .RightPartBlog{
  width: 73.8%;
}
.mob-left .LeftPartBlog{
  display: none;
}
/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .blog-detail {
    padding: 0 calc(15 / var(--rem)) calc(105 / var(--rem));
    max-width: calc(783 / var(--rem)); 
    margin-left:calc(139 / var(--rem)) ;
    flex-wrap: wrap;
  }
  .blog-detail .LeftPartBlog,
  .blog-detail .RightPartBlog {
    width:100%;
  }
  .mob-left .LeftPartBlog{
    display: block;
  }
  .blog-detail>.LeftPartBlog .part-2,
  .mob-left .LeftPartBlog .part-1 {
      display: none;
  }
}
@media(max-height:768px) and (max-width:1100px) and (orientation : landscape) {
  div.blog-detail{
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:600px){
  .blog-detail{
    margin: 0;
    padding: 0 calc(25 / var(--rem));
  }
  .BgView h1.blog-detail{
    justify-content: center;
  }
}