.BgView {
  width: 100%; 
  position: relative;
  margin-bottom: calc(129 / var(--rem));
  cursor: default;
}
.BgView >span{
  height:79.45vh;
  max-height: calc(887 / var(--rem));
  position: relative;
}
.BgView img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 5;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.BgView h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  color: #fff; 
  width: 90%;
  text-align: center;
  text-transform: uppercase;
  z-index: 3;
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: calc(80 / var(--rem));
  line-height: calc(120 / var(--rem));
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 0;
}
.BgView .layer {
  background-color: rgb(0 0 0 / 63%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.BgView h1 {
  position: absolute;
  color: #ff3e32;
  font-size: calc(72 / var(--rem));
  line-height: calc(110 / var(--rem));
  font-family: "Anton", "Arial", sans-serif;
  font-weight: 400;
  text-transform: uppercase; 
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  bottom: calc(-55 / var(--rem));
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap; 
  z-index: 10; 
  padding: 0 calc(20 / var(--rem));
  margin-bottom: 0;
}
.BgView h1.blog-title,
.BgView h1.blog-detail{
  padding-left: calc(397 / var(--rem));
}
.BgView h1.gateway-title {
  padding-left: calc(583 / var(--rem));
}
.BgView h1.team-banner{
  padding-left: calc(467 / var(--rem));
}
.BgView h1.graduate-title{ 
  padding-left: calc(474 / var(--rem));
}
.BgView h1.contact-title{ 
  padding-left: calc(474 / var(--rem));
}
.BgView h1.partner-title{ 
  padding-left: calc(504 / var(--rem));
}
.BgView h1.register-title{ 
  padding-left: calc(512 / var(--rem));
}
.BgView h1.about-title{ 
  padding-left: calc(524 / var(--rem));
}
.BgView h1.gateway-title{ 
  padding-left: calc(583 / var(--rem));
}
.lazy-load-image-background.black-and-white{
  width: 100%;
  display: block !important;
} 
/* ********************** responsive ********************** */
@media(min-width:1729px){
  .BgView h1 {
    max-width: 1077px;
    padding: 0 15px;
    left: 50%;
    transform: translateX(-50%);
  }
  .BgView h1.gateway-title {
    padding-left: 300px;
  } 
  .BgView h1.contact-title {
    max-width: 1152px;
    margin: 0 auto;
    padding: 0 10px 0 180px;
  } 
  .BgView h1.register-title{
    max-width: 1152px;
    margin: 0 auto;
    padding: 0 10px 0 260px;
  }
  .BgView h1.partner-title,
  .BgView h1.team-banner,
  .BgView h1.about-title,
  .BgView h1.graduate-title{
    max-width: 783px;
    padding-left: 15px;
  }
  .BgView h1.blog-title, .BgView h1.blog-detail{
    max-width: 943px;
    padding-left: 15px;
  }
}
@media(max-width:1199px){
  .BgView{
      margin-bottom: calc(100 / var(--rem));
  }
  .BgView h1{
    font-size: calc(59 / var(--rem));
    line-height: calc(88 / var(--rem));
    bottom: calc(-44 / var(--rem));
  }
  .BgView >span {
      height: 46.35vh;
      max-height: calc(633 / var(--rem));
      position: relative;
  }
  .BgView h1.gateway-title{
    padding: 0 calc(15 / var(--rem));
    text-align: center;
    font-size: calc(59 / var(--rem));
    line-height: calc(88 / var(--rem));
    bottom: calc(-44 / var(--rem));
  }
  .BgView h1.register-title,
  .BgView h1.partner-title,
  .BgView h1.team-banner,
  .BgView h1.about-title,
  .BgView h1.graduate-title,
  .BgView h1.blog-title, 
  .BgView h1.blog-detail{
    padding: 0 10px;
    text-align: center;
  }
  .BgView h1.contact-title {
    padding-left: calc(386 / var(--rem));
  }
  .BgView h4{
    font-size: calc(55 / var(--rem));
    line-height: calc(82 / var(--rem));
  } 
}

@media(max-height:768px) and (max-width:1100px) and (orientation : landscape) {
  .BgView >span {
    height: 60vh; 
  }
  .BgView h4 {
    font-size: calc(37 / var(--rem));
    line-height: calc(44 / var(--rem));
  }
}
@media(max-width:600px){
  .BgView {
    margin-bottom: calc(60 / var(--rem));
  }
  .BgView >span {
    height: 46.35vh;
    max-height: calc(256 / var(--rem));
    position: relative;
  }
  .About .BgView >span{ 
    max-height: calc(276 / var(--rem));
  }
  .Contact .BgView >span{ 
    max-height: calc(309 / var(--rem));
  }
  .Register .BgView >span{ 
    max-height: calc(415 / var(--rem));
  }
  .Partners .BgView >span{ 
    height: 51vh;
    max-height: calc(455 / var(--rem));
  }
  .BgView h1.gateway-title{
    font-size: calc(23 / var(--rem));
    line-height: calc(36 / var(--rem));
    bottom:calc(-18 / var(--rem)) ;
  }
  .BgView h1.contact-title {
    padding:0 calc(15 / var(--rem));
    text-align: center;
  }
  .BgView h1 { 
    font-size: calc(29 / var(--rem));
    line-height: calc(43 / var(--rem)); 
    bottom: calc(-22.5 / var(--rem));  
  }
  .BgView h4 {
    font-size: calc(18 / var(--rem));
    line-height: calc(27 / var(--rem));
}
.BgView h1.partner-title{
  font-size: calc(42 / var(--rem));
}

}