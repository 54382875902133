.Register {
  width: 100%;
  height: auto; 
}
.Register .content {
  padding: calc(53 / var(--rem)) calc(318 / var(--rem)) calc(216 / var(--rem)) calc(269 / var(--rem));
}
.Register .content>div{
  flex: unset;
}
/* Register Infos */
.Register .content .infos { 
  width:calc(460 / var(--rem));
  max-width: 100%;
}
.Register .content .form{
  width: calc(525 / var(--rem));
  max-width: 100%;
}
.Register .content .infos,
.Register .content .form {
  display: flex;
  flex-direction: column;
  justify-content: start-flex;
  align-items: start-flex;
  cursor: default;
}
.Register .content .infos h4 {
  padding: 0;
  font-weight: 600;
  font-size: calc(21 / var(--rem));
  line-height: calc(14 / var(--rem));
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: var(--black-color); 
  font-family: 'Barlow';
  margin-bottom: calc(26 / var(--rem));
  text-transform: uppercase;
}
.Register .content .infos p {
  padding: 0;
  font-weight: 400;
  font-size: calc(20 / var(--rem));
  line-height: calc(23 / var(--rem));
  letter-spacing: 0.02em;
  font-family: 'Barlow';
  margin: 0;
  color: #000;
}
.Register .content .infos p + p{
  margin-top: calc(23 / var(--rem));
}
/* Register Form */
.Register .content .form {
  margin-top: 0;
}
/* -------------- Responsive -------------- */ 
@media(min-width:1729px){
  .Register .content{
    max-width: 1152px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}
@media(max-width:1199px){
  .Register .content {
    padding: calc(14 / var(--rem)) calc(73 / var(--rem)) calc(100 / var(--rem)) calc(53 / var(--rem));
  }
  .Register .content .infos {
    width: calc(390 / var(--rem));
    max-width: 100%;
  }
 .Register .content .form {
    width: calc(442 / var(--rem));
  }
  .Register .content .infos h4 { 
    font-size: calc(18 / var(--rem));
    line-height: calc(12 / var(--rem)); 
    margin-bottom: calc(22 / var(--rem)); 
    }
  .Register .content .infos p { 
    font-size: calc(17 / var(--rem));
    line-height: calc(19 / var(--rem)); 
  }
  .Register .content .infos p + p {
    margin-top: calc(19 / var(--rem));
  }
}
@media(max-width:600px){
  .Register .content {
    padding: calc(16 / var(--rem)) calc(23 / var(--rem)) calc(100 / var(--rem)) calc(29 / var(--rem));
  }
  .Register .content .infos{
    margin-bottom: calc(68 / var(--rem));
  }
  .Register .content .infos p {
    font-size: calc(13 / var(--rem));
    line-height: calc(20 / var(--rem));
  }
  .Register .content .infos > p:last-of-type{
    max-width:calc(270 / var(--rem));
  }
  .Register  .form-group.w-240 {
    max-width: calc(150 / var(--rem)) !important;
  }
}