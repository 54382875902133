.Intro {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--black-color);
  overflow: hidden;
  user-select: none;
  overflow-y: auto;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.Intro .bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.Intro .bg-intro {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Intro .content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  cursor: default;
  padding: calc(20 / var(--rem)); 
}
.Intro .content .intro-card {
  position: relative;
  width: calc(671 / var(--rem));
  max-width: 100%;
  height:calc(443 / var(--rem));
}
.Intro .content .intro-card div {
  width: calc(100% - calc(50 / var(--rem)));
  height: calc(100% - calc(50 / var(--rem))); 
  background-color:  #F4F4F4;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 100%;
}
.Intro .content .intro-card .logo {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: calc(-30/ var(--rem));
}
.Intro .content .intro-card .logo p {
  position: relative;
  font-family: "Playfair Display"  !important;
  font-size: calc(90/ var(--rem));
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #1D1B1B;
  text-transform: uppercase;
  height: auto;
  margin-bottom: calc(-20/ var(--rem)) !important;
  line-height: calc(120/ var(--rem));
}
.Intro .content .intro-card .logo span {
  position: relative;
  font-family: "Playfair Display" !important;
  font-size: calc(14/ var(--rem));
  font-weight: 700;
  letter-spacing: 0.53em;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #1D1B1B;
  text-transform: capitalize;
  line-height: calc(21/ var(--rem));
}
.Intro .content .intro-card ul {
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
  padding-bottom: calc(30/ var(--rem));
}
.Intro .content .intro-card ul li {
  display: inline-block;
  flex-direction: row;
  font-family: 'Poppins' , sans-serif;
  font-size: calc(15/ var(--rem));
  line-height: calc(22/ var(--rem));  
  text-align: center;
  text-transform: uppercase; 
  color: #000; 
  margin: 0 calc(10/ var(--rem));
  font-weight: 500;
} 
.Intro .content .intro-card .img-border {
  position: relative;
  width: 100%;
  height: 100%;
}
.Intro .content .intro-card + p {
  font-family: "Anton", "Arial", sans-serif;
  font-size: calc(18/ var(--rem));
  line-height: calc(28/ var(--rem)); 
  margin-top: calc(25/ var(--rem));
  text-align: center;
  text-transform: uppercase;
  max-width: calc(662/ var(--rem));
  color: #fff;
  margin-bottom: calc(107/ var(--rem));
}
.Intro .content .ButtonSimple{
  margin-top: 0;
}
.Intro .content .ButtonSimple a {
  background-color: #F4F4F4 !important;
  font-size: calc(13/ var(--rem));
  line-height: calc(19/ var(--rem));
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .Intro .content .intro-card { 
    width: calc(801 / var(--rem)); 
    height: calc(530 / var(--rem));
  }
  .Intro .content .intro-card div{
    justify-content: center !important;
  }
  .Intro .content .intro-card div.logo{
    transform: unset;  
    left: unset;
    top: unset;
    margin-top: 0;
  }
  .Intro .content .intro-card .logo p { 
    font-size: calc(108/ var(--rem)); 
    line-height: calc(144/ var(--rem));
    margin-bottom: 0 !important;
  }
  .Intro .content .intro-card .logo span{
    font-size:calc(16/ var(--rem)) ;
    line-height: calc(26/ var(--rem));
  }
  .Intro .content .intro-card ul {  
    padding-bottom: 0;
  }
  .Intro .content .intro-card ul li { 
    font-size: calc(18/ var(--rem));
    line-height: calc(27/ var(--rem)); 
    margin: calc(28/ var(--rem)) calc(12/ var(--rem)) 0;
  }
  .Intro .content .intro-card + p { 
    font-size: calc(22/ var(--rem));
    line-height: calc(33/ var(--rem));
    margin-top: calc(34/ var(--rem)); 
    max-width: calc(800/ var(--rem));  
  }
  .Intro .content .ButtonSimple a{
    padding:  calc(11/ var(--rem))  calc(46/ var(--rem))  calc(11/ var(--rem))  calc(50/ var(--rem));
    min-width: auto;
  }
}
@media(max-width:600px){
  .Intro .content .intro-card {
    width: calc(355 / var(--rem));
    height: calc(235 / var(--rem));
  }
  .Intro .content .intro-card div {
    width: calc(100% - calc(26 / var(--rem)));
    height: calc(100% - calc(26 / var(--rem)));
    background-color: #F4F4F4; 
  }
  .Intro .content .intro-card .logo p {
    font-size: calc(48/ var(--rem));
    line-height: calc(58/ var(--rem)); 
    margin-top:calc(-10/ var(--rem)) ;
  }
  .Intro .content .intro-card .logo span {
    font-size: calc(7.45/ var(--rem));
    line-height: calc(11/ var(--rem));
  }
  .Intro .content .intro-card ul li {
    font-size: calc(8/ var(--rem));
    line-height: calc(12/ var(--rem));
    margin: calc(12/ var(--rem)) calc(5/ var(--rem)) 0;
  }
  .Intro .content .intro-card + p {
    font-size: calc(10/ var(--rem));
    line-height: calc(15/ var(--rem));
    margin-top: calc(15/ var(--rem));
    max-width: calc(346/ var(--rem));
    margin-bottom: calc(50/ var(--rem));
    /* flex-grow: 1; */
  }
  .Intro .content .ButtonSimple a{
    font-size: calc(7/ var(--rem));
    line-height: calc(10/ var(--rem));
    min-width: calc(116/ var(--rem));
    padding:  calc(5/ var(--rem))  calc(23/ var(--rem))  calc(5/ var(--rem))  calc(23/ var(--rem));
  }
  .Intro{
    background-image: url(../../assets/images/mobile-bg.png) !important; 
    flex-direction: unset;
    justify-content: center;
    align-items: unset;
  }
  .Intro .content{
    padding-top: 25vh;
    padding-bottom: calc(83/ var(--rem));
    justify-content: flex-start;
  }
}