.LeftPartGateway {
  height: auto; 
  cursor: default;
}
/* First Part */
.LeftPartGateway .part-1 {
  margin-bottom: calc(178 / var(--rem));
}
.LeftPartGateway .part-1 h6,
.LeftPartGateway .part-2 h6 {
  text-transform: capitalize;
  font-size: calc(15 / var(--rem));
  line-height: calc(18 / var(--rem));
  margin-bottom: calc(5 / var(--rem));
  font-family : "Barlow"; 
  font-weight: 400;
  color: #000;
}
.LeftPartGateway .part-2 h6{
  font-size: calc(17 / var(--rem));
  line-height: calc(32 / var(--rem));
  font-weight: 600;
  color: #040404;
}
.LeftPartGateway .part-1 h4 {
  text-transform: capitalize;
  font-family : "Barlow"; 
  font-weight: 400;
  font-size: calc(32 / var(--rem));
  line-height: calc(38 / var(--rem));
  margin-bottom: calc(12 / var(--rem));
  color: #000;
}
.LeftPartGateway .part-1 p {
  text-decoration: underline !important;
  color: var(--brouwn-color) !important;
  font-size: calc(20 / var(--rem));
  line-height: calc(24 / var(--rem));
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  margin-bottom: 0;
  font-family : "Barlow";  
}
.LeftPartGateway .part-1 p:hover {
  text-decoration: none !important;
  transition: all 0.4s ease-in-out;
}

/* Second Part */
.LeftPartGateway .part-2 p {
  color:#878787;
  font-size: calc(16 / var(--rem));
  line-height:calc(23 / var(--rem));
  margin: calc(13 / var(--rem)) 0 calc(25 / var(--rem)) 0;
  font-family : "Barlow"; 
  font-weight: 400;
}
.LeftPartGateway .part-2 .sdg-imgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: flex-start; 
  padding: 0 calc(9 / var(--rem));
}
.LeftPartGateway .part-2 .sdg-imgs img {
  width: calc(83 / var(--rem));
  height: calc(83 / var(--rem));
  margin-bottom: calc(7 / var(--rem));
}
.LeftPartGateway .part-2 .sdg-imgs>img:nth-of-type(odd){
  margin-right: calc(7 / var(--rem));
}
/********************** Modal  *****************************/
.cv-details-modal {
  overflow: hidden;
  padding-top:  11vh;
}
.cv-details-modal .modal-dialog .modal-content {
  max-height: 90vh;
  height:  calc(840/ var(--rem));
  padding:  calc(10/ var(--rem))   calc(10/ var(--rem))  ;
}
.cv-details-modal .modal-dialog {
  margin: 0 auto !important;
  max-width:  calc(1303/ var(--rem));
  width: 100%;
}
.modal-content .modal-header {
  border-bottom: none;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 9;
}
.cv-details-modal .modal-dialog .modal-content .modal-body {
  overflow-y: auto; 
  padding: 0  calc(116/ var(--rem));
}
.cv-details-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar {
  background: transparent;
  width:  calc(8/ var(--rem));
  height:  calc(8/ var(--rem));
}
.cv-details-modal  .modal-dialog  .modal-content  .modal-body::-webkit-scrollbar-thumb {
  background: #cac8c8;
  border-radius:  calc(30/ var(--rem));
  transition: 0.2s ease-in-out;
}
.cv-details-modal  .modal-dialog  .modal-content  .modal-body::-webkit-scrollbar-thumb:hover {
  background: #dbd9d9;
  transition: 0.2s ease-in-out;
}
.cv-details-modal   .modal-dialog  .modal-content  .modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0  calc(6/ var(--rem)) #39ff8500;
  background-color: transparent;
} 
.cv-details-modal .cv-details{
  padding:  calc(118/ var(--rem))  0;
}
.cv-details-modal .cv-details ul {
  margin: 0;
}
.cv-details-modal .cv-details .titles-part {
  margin-bottom:  calc(73/ var(--rem));
}
.cv-details-modal .cv-details .titles-part h3 {
  font-family: 'Barlow'; 
  font-weight: 600;
  font-size:  calc(40/ var(--rem));
  line-height:  calc(49/ var(--rem)); 
  letter-spacing: 0.02em;
  color: #363636; 
  text-transform: uppercase;
  margin: 0;
}
.cv-details-modal .cv-details .titles-part h5 {
  color: #363636; 
  /* text-transform: capitalize; */
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size:  calc(26.9772/ var(--rem));
  line-height:  calc(32/ var(--rem));
  letter-spacing: 0.02em;
  margin: 0;
}
.cv-details-modal .cv-details .list-part {
  color: var(--light-black-color) !important;
}
.cv-details-modal .cv-details .list-part h6 {
  text-transform: uppercase;
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size:  calc(26.9772/ var(--rem));
  line-height:  calc(32/ var(--rem));
  letter-spacing: 0.02em;
  margin-bottom:  calc(19/ var(--rem));
  color: #363636; 
}
.cv-details-modal .cv-details .list-part ul li {
  list-style: disc;
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size:  calc(21.5818/ var(--rem));
  line-height: calc( 27/ var(--rem)); 
  text-align: justify;
  color: #363636; 
  letter-spacing: 0.02em;
}
.cv-details-modal .cv-details .list-part ul li::marker {
  font-size: 0.7em;
  color: var(--black-color);
}
.cv-details-modal .cv-details .list-part {
  margin-bottom:  calc(42/ var(--rem));
}
.cv-details-modal .cv-details .list-part:last-child {
  margin-bottom: 0;
}
.ux-uidesign:not([data-value="active"]),
.ux-uidesign[data-value="active"]~div{
  display: none;
} 
.jewellery-des ~ .list-part p,.uiux-des ~ .list-part p{
  font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size:  calc(21 / var(--rem));
line-height:  calc(27 / var(--rem)); 
text-align: justify;
letter-spacing: 0.02em; 
color: #363636;
margin-bottom:  calc(53 / var(--rem));
}
.jewellery-des ~ .list-part ul{
  margin-bottom:  calc(53 / var(--rem));
}
.uiux-des ~ .list-part ul li{
  margin-bottom:  calc(27 / var(--rem));
}
.uiux-des ~ .list-part p{
  margin-bottom:  calc(20 / var(--rem));
}
.list-number>li,
.list-number >ul >li {
  list-style-type: decimal !important;
}
.list-number>li ul li{
  list-style-type: disc !important;
}
.list-number ul li::marker,
.list-number>li::marker {
  font-size: inherit !important;
}
.no-disc-list ul{
  padding-left: 0;
}
.no-disc-list li {
  list-style-type: none !important;
}
.normal-text{
  text-transform: initial !important;
}

 /* ========= Responsive =========== */
@media(min-width:1729px){
  .LeftPartGateway .part-2 .sdg-imgs img{
    width: calc(50% - 4px);
  }
}
@media(max-width:1199px){ 
  .LeftPartGateway .part-1 h6, .LeftPartGateway .part-2 h6 { 
    font-size: calc(16 / var(--rem));
    line-height: calc(20 / var(--rem)); 
  }
  .LeftPartGateway .part-1 h4 { 
    font-size: calc(36 / var(--rem));
    line-height: calc(43 / var(--rem));
    max-width: calc(240 / var(--rem));
    min-height: calc(86 / var(--rem));
    margin-bottom: calc(13 / var(--rem));
  }
  .LeftPartGateway .part-1 p { 
    font-size: calc(22 / var(--rem));
    line-height: calc(27 / var(--rem));
  }
  .LeftPartGateway .part-1 {
    margin-bottom: calc(101 / var(--rem));
  }
  .LeftPartGateway .part-2 p { 
    font-size: calc(18 / var(--rem));
    line-height: calc(25 / var(--rem));
    margin: calc(19 / var(--rem)) 0 calc(31 / var(--rem)) 0; 
  }
  .LeftPartGateway .part-2 .sdg-imgs{
    margin-top: calc(34 / var(--rem));
    margin-bottom: calc(64 / var(--rem));
  }
  .LeftPartGateway .part-2 .sdg-imgs img { 
    margin-right: calc(8 /  var(--rem));
  }
  .cv-details-modal .modal-dialog { 
    max-width: calc(964/ var(--rem)); 
  }
  .cv-details-modal .modal-dialog .modal-content { 
    height: calc(890/ var(--rem)); 
  }
  .cv-details-modal .modal-dialog .modal-content .modal-body {
    padding: 0 calc(84/ var(--rem));
  }
  .cv-details-modal .cv-details {
    padding: calc(84/ var(--rem)) 0;
  }
  .cv-details-modal .cv-details .titles-part h3 { 
    font-size: calc(29/ var(--rem));
    line-height: calc(35/ var(--rem)); 
  }
  .cv-details-modal .cv-details .titles-part h5,
  .cv-details-modal .cv-details .list-part h6 { 
    font-size: calc(20/ var(--rem));
    line-height: calc(24/ var(--rem)); 
  }
  .cv-details-modal .cv-details .titles-part {
    margin-bottom: calc(53/ var(--rem));
  }
  .cv-details-modal .cv-details .list-part ul li { 
    font-size: calc(16/ var(--rem));
    line-height: calc( 20/ var(--rem)); 
  }
  .cv-details-modal .cv-details .list-part {
    margin-bottom: calc(30/ var(--rem));
  }
  .jewellery-des ~ .list-part p,.uiux-des ~ .list-part p{ 
  font-size:  calc(15 / var(--rem));
  line-height:  calc(20 / var(--rem)); 
  margin-bottom:  calc(40 / var(--rem));
  }
  .jewellery-des ~ .list-part ul{
    margin-bottom:  calc(40 / var(--rem));
  }
  .uiux-des ~ .list-part ul li{
    margin-bottom:  calc(27 / var(--rem));
  }
  .uiux-des ~ .list-part p{
    margin-bottom:  calc(20 / var(--rem));
  }
}
@media(max-width:600px){
  .LeftPartGateway .part-1 h6  {
    font-size: calc(11 / var(--rem));
    line-height: calc(13 / var(--rem));
    margin-bottom: calc(3 / var(--rem));
  }
  .LeftPartGateway .part-1 h4 {
    font-size: calc(24 / var(--rem));
    line-height: calc(39 / var(--rem));
    max-width: calc(141 / var(--rem));
    margin-bottom: calc(9 / var(--rem));
    min-height: calc(58 / var(--rem)); 
  }
  .LeftPartGateway .part-1 p {
    font-size: calc(15 / var(--rem));
    line-height: calc(18 / var(--rem));
  }
  .LeftPartGateway .part-1 {
    margin-bottom: calc(47 / var(--rem));
  }
  .LeftPartGateway .part-2 h6{
    font-size: calc(17 / var(--rem));
    line-height: calc(32 / var(--rem));
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: calc(1 / var(--rem));
  }
  .LeftPartGateway .part-2 p {
    font-size: calc(16 / var(--rem));
    line-height: calc(23 / var(--rem));
    margin: calc(18 / var(--rem)) 0 calc(24 / var(--rem)) 0;
    max-width: calc(353 / var(--rem));
  }
  .LeftPartGateway .part-2 .sdg-imgs{
    margin-top: calc(21 / var(--rem));
    margin-bottom: calc(23 / var(--rem));
  }
  .LeftPartGateway .part-2 .sdg-imgs img {
    width: calc(93 / var(--rem));
    height: calc(93 / var(--rem));
    margin-bottom: calc(8 / var(--rem));
  }
  .jewellery-des ~ .list-part p,.uiux-des ~ .list-part p{ 
  font-size:  calc(13 / var(--rem));
  line-height:  calc(19 / var(--rem)); 
  margin-bottom:  calc(18 / var(--rem));
  }
  .jewellery-des ~ .list-part ul{
    margin-bottom:  calc(18 / var(--rem));
  }
  .uiux-des ~ .list-part ul li{
    margin-bottom:  calc(18 / var(--rem));
  }
  .uiux-des ~ .list-part p{
    margin-bottom:  calc(18 / var(--rem));
  }
  .cv-details-modal .cv-details {
    padding: calc(66/ var(--rem)) 0 calc(34/ var(--rem));
  }
  .cv-details-modal .modal-dialog .modal-content .modal-body {
    padding: 0 calc(28/ var(--rem));
  }
  .part-2 hr {
    width: calc(194/ var(--rem));
  }
  .fade.cv-details-modal.modal.show {
    padding:  calc(27/ var(--rem))  0 !important;
  }
  .cv-details-modal .cv-details .titles-part h3 {
    font-size: calc(20/ var(--rem));
    line-height: calc(24/ var(--rem)); 
  }
  .cv-details-modal .cv-details .titles-part h5, .cv-details-modal .cv-details .list-part h6 {
    font-size: calc(16/ var(--rem));
    line-height: calc(20/ var(--rem));
  }
  .cv-details-modal .cv-details .titles-part {
    margin-bottom: calc(30/ var(--rem));
  }
  .cv-details-modal .cv-details .list-part h6 {
    font-size: calc(16/ var(--rem));
    line-height: calc(20/ var(--rem));
    margin-bottom: calc(11/ var(--rem));
  }
  .cv-details-modal .cv-details .list-part ul li { 
    font-size: calc(16/ var(--rem));
    line-height: calc( 20/ var(--rem)); 
  } 
  .jewellery-des ~ .list-part p,.uiux-des ~ .list-part p, 
  .cv-details-modal .cv-details .list-part ul li {
    font-size: calc(13/ var(--rem));
    line-height: calc( 16/ var(--rem));
  }
  .cv-details-modal .modal-dialog {
    width: calc(387/ var(--rem));
  }
}