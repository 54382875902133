.BottomPartBlog {
  width: 100%; 
  background-color: var(--black-color); 
  padding: calc(191/ var(--rem)) 0 calc(199/ var(--rem));
}
.blog-bottom-inner{
  max-width: calc(832/ var(--rem));
  padding: 0 calc(15/ var(--rem));
  margin: 0 auto;
}

/* First part */
.BottomPartBlog h3 {
  font-family: 'Anton'; 
  font-weight: 400;
  font-size: calc(70/ var(--rem));
  line-height: calc(83/ var(--rem)); 
  letter-spacing: 0.02em;
  text-transform: uppercase; 
  color: #FFFFFF; 
  margin-bottom: calc(22/ var(--rem));
}
.BottomPartBlog p { 
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(18/ var(--rem));
  line-height: calc(32/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #FFFFFF;
  margin-bottom: calc(32/ var(--rem)); 
}

/* Second Part */
.BottomPartBlog .SubscribeForm {
  margin-top: calc(90/ var(--rem));
  display: inline-block;
  width: 100%;
  max-width: calc(581/ var(--rem));
}
.BottomPartBlog .SubscribeForm .form-control {
  background-color: transparent;
  color: var(--white-color);
}
.BottomPartBlog .SubscribeForm .ButtonSubmit {
  justify-content: flex-start !important;
  margin-top: calc(110/ var(--rem));
}
.BottomPartBlog .SubscribeForm .ButtonSubmit button:hover {
  border: 1px solid var(--black-color);
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .blog-bottom-inner {
    max-width: calc(669 / var(--rem)); 
    margin: 0 auto;
  }
  .BottomPartBlog {
    width: 100%;
    background-color: var(--black-color);
    padding: calc(84 / var(--rem)) 0 calc(66 / var(--rem));
  }
  .BottomPartBlog h3{
    line-height: calc(85 / var(--rem)) ;
    margin-bottom: calc(40 / var(--rem));
  }
  .BottomPartBlog p{
    margin-top: 0;
  }
  .BottomPartBlog .SubscribeForm {
    margin-top: calc(39 / var(--rem));
  }
  .BottomPartBlog .SubscribeForm .ButtonSubmit { 
    margin-top: calc(72 / var(--rem));
  }
}
@media(max-width:600px){
  .BottomPartBlog{
    padding: 0;
  }
  .blog-bottom-inner{
    padding: calc(30 / var(--rem))  calc(57 / var(--rem))  calc(28 / var(--rem));
  }
  .BottomPartBlog h3 {
    font-size: calc(28 / var(--rem));
    line-height: calc(32 / var(--rem));
    margin-bottom: calc(18 / var(--rem));
  }
  .BottomPartBlog p {  
    font-size: calc(11/ var(--rem));
    line-height: calc(16/ var(--rem)); 
  }
  .BottomPartBlog .SubscribeForm .ButtonSubmit {
    margin-top: calc(41 / var(--rem));
  }
  .BottomPartBlog p{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .BottomPartBlog p+p{
    margin-top: calc(16/ var(--rem)) !important;
  }
  .BottomPartBlog .SubscribeForm {
    margin-top: calc(22 / var(--rem));
}
}