.About {
  width: 100%;
  height: auto;
} 
.About .content {
  width:calc( 783/ var(--rem));
  padding: calc(100/ var(--rem)) calc(15/ var(--rem)) ; 
  margin: 0 0 0 calc(524/ var(--rem));
  cursor: default;
}
.About .content h5 {
  font-family: 'Barlow'; 
  font-weight: 600;
  font-size: calc(24/ var(--rem));
  line-height: calc(29/ var(--rem)); 
  color: #000000; 
  letter-spacing: 0.02em;
  margin-bottom: calc(30/ var(--rem));
}
.About .content p,.About .content ul li {
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(18/ var(--rem));
  line-height: calc(32/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  margin-bottom: calc(30/ var(--rem));
}
.About .content ul {
  margin-bottom: calc(30/ var(--rem));
  list-style: disc;
}
.About .content ul.decimal { 
  list-style: decimal;
}
.About .content ul li{
  margin-bottom: 0;
}
.About .BodyPart .quote p{
  font-family: 'Anton'; 
  font-weight: 400;
  font-size: calc(24/ var(--rem));
  line-height: calc(42/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
}
/* -------------- Responsive -------------- */ 
@media(min-width:1729px){
  .About .content{
    margin: 0 auto;
  }
}
@media(max-width:1199px){ 
  .About .content {
    width: calc( 783/ var(--rem));
    padding: 0 calc(15/ var(--rem)) calc(100/ var(--rem));
    margin: 0 0 0 calc(114/ var(--rem)); 
  }
}
@media(max-height:768px) and (max-width:1100px) and (orientation : landscape) {
  .About .content{
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:600px){
  .About .content {
    width: 100%;
    padding: 0 calc(23/ var(--rem)) calc(38/ var(--rem)) calc(26/ var(--rem));
    margin: 0;
  }
  .About .content h5{
    font-size: calc(14/ var(--rem));
    line-height: calc(17/ var(--rem));
    margin-bottom: calc(18/ var(--rem));
  }
  .About .content p ,.About .content ul li{ 
    font-size: calc(13/ var(--rem));
    line-height: calc(20/ var(--rem)); 
    margin-bottom: calc(20/ var(--rem));
  }
  .BodyPart img{
    padding: 0 0 calc(40/ var(--rem)) 0;
  }
  .About .BodyPart .quote{
    padding-left:calc(5/ var(--rem)) ;
  }
  .About .BodyPart .quote p { 
    font-size: calc(12/ var(--rem));
    line-height: calc(21/ var(--rem)); 
    margin-right:calc(-10/ var(--em)) ;
  }
}