.RightPartBlog {
  height: auto;  
} 
.RightPartBlog .quote {
  border-left: 1px solid var(--red-color);
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: calc(46/ var(--rem));
  padding: calc(20/ var(--rem)) 0 calc(20/ var(--rem)) calc(10/ var(--rem));
}
.RightPartBlog .quote svg {
  transform: rotate(180deg);
  color: var(--red-color);
  margin-top: -calc(12/ var(--rem));
}
.RightPartBlog .quote p {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: calc(24/ var(--rem));
  line-height: calc(42/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  margin: 0;
  margin-left: calc(3/ var(--rem));
} 
.RightPartBlog h5 {
  font-family: 'Barlow'; 
  font-weight: 500;
  font-size: calc(24/ var(--rem));
  line-height: calc(29/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  margin-bottom: calc(20/ var(--rem));
}
.RightPartBlog  p{
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(18/ var(--rem));
  line-height: calc(32/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  margin-bottom: calc(28/ var(--rem));
}
.RightPartBlog .imgs {
  width: 100%; 
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.RightPartBlog .imgs figure{
  margin-bottom: calc(30/ var(--rem));
}
.RightPartBlog .imgs figure img{
  width: 100%;
}
.RightPartBlog .imgs .img-left,
.RightPartBlog .imgs .img-right {
  width: calc(50% - calc(44/ var(--rem)));
}
.RightPartBlog .imgs .img-right figure{
  margin-top: calc(148/ var(--rem));
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .RightPartBlog .quote { 
    margin-bottom: calc(36 / var(--rem)); 
  }
  .RightPartBlog h5{
    margin-bottom: calc(30 / var(--rem));
  }
}
@media(max-width:600px){
  .RightPartBlog .quote p{
    font-size: calc(17/ var(--rem));
    line-height: calc(30/ var(--rem));
  }
  .RightPartBlog .quote { 
    margin-bottom: calc(20 / var(--rem)); 
  }
  .RightPartBlog h5 { 
    font-size: calc(17/ var(--rem));
    line-height: calc(21/ var(--rem));
    margin-bottom: calc(16 / var(--rem)); 
  }
  .RightPartBlog p { 
    font-size: calc(13/ var(--rem));
    line-height: calc(23/ var(--rem)); 
    margin-bottom: calc(23/ var(--rem));
  }
  .RightPartBlog .imgs{
    flex-wrap: wrap;
  }
  .RightPartBlog .imgs .img-left, .RightPartBlog .imgs .img-right {
    width: calc(226/ var(--rem));
  } 
  .RightPartBlog .imgs .img-right {
    margin-left: auto;
  }
  .RightPartBlog .imgs .img-right figure {
    margin-top: 0
  }
  .part-2 {
    margin-top: calc(31 / var(--rem));
}
}