.cs-container{
  max-width: calc(1077/ var(--rem));
  width: 100%;
  margin-left: calc(278/ var(--rem));
}  
.cs-container >.row{
  margin-left:calc( -15/ var(--rem)) !important;
  margin-right: calc(-15/ var(--rem)) !important;
}
.cs-left-part{
  width: 19.7% !important;
  padding: 0 calc(15/ var(--rem));
  flex:  unset !important;
}
.cs-right-part{
  width: 80.3% !important;
  padding: 0 calc(15/ var(--rem));
  flex:  unset !important;
}
.hide-text{
  display: none;
}
/* -------------- Responsive -------------- */
 @media(max-width:1199px){
  
  .cs-container {
    margin-left: 0;
    padding: calc(13 / var(--rem)) calc(78 / var(--rem)) 0 ;
    max-width: 100%;
  }
  .cs-container >.row{
    margin: 0 !important;
  }
  .cs-left-part,.cs-right-part{
    width: 100% !important;
    padding: 0 !important;
  }
 }
 @media(max-width:600px){
  .cs-container {
    margin-left: 0;
    padding: calc(20 / var(--rem)) calc(30 / var(--rem)) calc(56 / var(--rem));
    max-width: 100%;
  }
}