.RightPartGateway { 
  padding: 0;
  cursor: default;
  padding-left: calc(86  / var(--rem));
}
.RightPartGateway >.top-cs-text{
  max-width: calc(753  / var(--rem));
}
.RightPartGateway p ,.top-cs-text{
  font-size: calc(18  / var(--rem));
  line-height: calc(32  / var(--rem));
  font-weight: 400;
  color: var(--black-color);
  position: relative;
  margin-bottom: calc(30  / var(--rem));
  font-family : "Barlow"; 
  /* letter-spacing: calc(0.1  / var(--em)); */
  letter-spacing: calc(0.36  / var(--em)); 
}
.top-cs-text a,
.RightPartGateway p >a {
  font-size: inherit;
  line-height: inherit;
  color: var(--brouwn-color) !important;
}
.RightPartGateway .imgs {
  width: 100%;
  margin-top: calc(37  / var(--rem));
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.RightPartGateway .imgs .img-left,
.RightPartGateway .imgs .img-right {
  width: 50%;
  max-width: calc(347  / var(--rem));
}
.RightPartGateway .imgs .img-right{  
  margin-right: calc(45  / var(--rem));
}
.RightPartGateway .imgs .img-left {
  margin-top: calc(119  / var(--rem));
} 
.RightPartGateway .imgs .cover-img{
  padding-bottom:146.2%;
}
.RightPartGateway .quote {
  border-left: calc(1  / var(--rem)) solid var(--red-color);
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  margin: calc(60  / var(--rem)) 0;
  padding: calc(20  / var(--rem)) 0 calc(20  / var(--rem)) calc(10  / var(--rem));
  max-width: calc(728  / var(--rem));
}
.RightPartGateway .quote svg {
  transform: rotate(180deg);
  color: var(--red-color);
  margin-top:  calc(-10 / var(--em));
}
.RightPartGateway .quote p {
  font-family: "Anton", "Arial", sans-serif;
  font-size:  calc(24 / var(--rem));
  line-height:  calc(42 / var(--rem));
  font-weight: 400;
  margin-left:  calc(3 / var(--rem));
}
.RightPartGateway a {
  font-size:  calc(28 / var(--rem));
  font-weight: 500;
  margin-top:  calc(20 / var(--rem));
  display: inline-block;
  line-height:  calc(33 / var(--rem));
  font-family: 'Barlow';
}
.RightPartGateway a svg {
  transform: translateX( calc(0 / var(--rem)));
  transition: all 0.4s ease-in-out; 
  width: calc(30.2 / var(--em)); 
}
.RightPartGateway a:hover svg {
  transform: translateX( calc(10 / var(--rem)));
  transition: all 0.4s ease-in-out;
}
.RightPartGateway .quote + span p{
  max-width: calc(769 / var(--rem));
}
/* -------------- Responsive -------------- */ 
@media(min-width:1729px){
  .RightPartGateway{
    padding-left: 80px;
  }
}
@media(max-width:1199px){
  .RightPartGateway { 
    padding:0 calc(6  /  var(--rem));
  } 
  .RightPartGateway >p{
    max-width:calc(807  /  var(--rem)) ;
  }
  .RightPartGateway .imgs{
    padding: 0 calc(24 / var(--rem)) 0 0;
    margin-top: calc(32 / var(--rem));
  }
  .RightPartGateway .imgs{
    flex-wrap: wrap;
  }
  .RightPartGateway .imgs figure{
    margin-bottom: 0;
  }
  .RightPartGateway .imgs .img-left, .RightPartGateway .imgs .img-right{
    width: 100%;
    max-width: 100%;
  }
  .RightPartGateway .imgs .img-right{
    max-width:calc(655 / var(--rem)) ;
  }
  .RightPartGateway .imgs .img-left{
    max-width: calc(717 / var(--rem));
    margin-top:calc(109 / var(--rem)) ;
    margin-left: auto;
  } 
  .RightPartGateway p,
  .top-cs-text{
    letter-spacing: calc(0.3 / var(--em));
  }
  .RightPartGateway a {
    font-size: calc(32 / var(--rem)); 
    margin-top: calc(20 / var(--rem)); 
    line-height: calc(38 / var(--rem));
  }
}
@media(max-width:600px){
  .RightPartGateway{
    padding: 0;
  }
  .RightPartGateway p ,
  .top-cs-text{
    font-size: calc(13 / var(--rem));
    line-height: calc(20 / var(--rem)); 
    margin-bottom: calc(20 / var(--rem)); 
    max-width:  calc(346 / var(--rem));
  }
  .RightPartGateway .imgs {
    padding: 0  ; 
  }
  .RightPartGateway .imgs .img-right{
    margin-right: 0;
  }
  .RightPartGateway .imgs .img-right {
    max-width: calc(283 / var(--rem));
  }
  .RightPartGateway .imgs .img-left {
    max-width: calc(302 / var(--rem));
    margin-top: calc(77 / var(--rem)); 
  }
  .RightPartGateway .quote { 
    margin: calc(22 / var(--rem)) 0 calc(40 / var(--rem));
    padding: calc(20 / var(--rem)) 0 calc(20 / var(--rem)) calc(21 / var(--rem)); 
  }
  .RightPartGateway .quote p { 
    font-size: calc(20 / var(--rem));
    line-height: calc(32 / var(--rem)); 
    margin-left: calc(3 / var(--rem));
    margin-bottom: 0;
    letter-spacing: calc(0.1 / var(--em));
  }
  .RightPartGateway a {
    font-size: calc(12 / var(--rem));
    margin-top: calc(26 / var(--rem));
    line-height: calc(15 / var(--rem));
  }
  .RightPartGateway a svg {
    width: calc(16 / var(--rem));
    height: calc(16 / var(--rem));
    margin-left: calc(3 / var(--rem));
  }
}