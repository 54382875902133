.BottomPartGateway {
  width: 100%;
  height: auto;
  cursor: default;
  margin-top:  calc(87  / var(--rem));
}

/* First part */
.BottomPartGateway .donation {
  background-color: var(--black-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:  calc(190  / var(--rem))  calc(190  / var(--rem))  calc(199  / var(--rem))  calc(583  / var(--rem));
  height: auto;
}
.BottomPartGateway .donation > *{
  max-width:  calc(769  / var(--rem));
}
.BottomPartGateway .donation h3 {
  font-family: "Anton", "Arial", sans-serif;
  color: var(--white-color); 
  font-size:  calc(70  / var(--rem));
  line-height:  calc(83  / var(--rem));
  font-weight: 400;
  margin-bottom:  calc(22  / var(--rem));
  text-transform: uppercase;
  max-width:  calc(404  / var(--rem));
}
.BottomPartGateway .donation p {
  color: var(--white-color);
  font-size:  calc(18  / var(--rem));
  line-height:  calc(32  / var(--rem));
  margin-bottom:  calc(32  / var(--rem));
  font-family: "Barlow";
  letter-spacing: calc(0.37  / var(--rem));
} 
.ButtonSimple{
  margin-top:  calc(117  / var(--rem));
}
.ButtonSimple a{
  display: inline-block;
}
/* Second Part */
.BottomPartGateway .next-post {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:  calc(95  / var(--rem)) 0  calc(168  / var(--rem));
  max-width:  calc(1269  / var(--rem));
  margin: 0 auto;
}
.BottomPartGateway .next-post p {
  font-weight: 600;
  margin: 0;
  text-align: center;
  font-family: 'Poppins';
  font-size:  calc(10  / var(--rem));
  line-height:  calc(15  / var(--rem));
  margin-bottom:  calc(2  / var(--rem));
  color:#878787;
  letter-spacing:  calc(1.7  / var(--rem));
} 
.BottomPartGateway .next-post p:last-child {
  color: #1D1D1D;
  font-size:  calc(16  / var(--rem));
  line-height:  calc(24  / var(--rem));
  font-family: 'Anton';
  font-weight: 400;
  letter-spacing:calc( 3.5  / var(--rem)) ;
}
.BottomPartGateway .next-post a>p{
  position: relative;
}
.BottomPartGateway .next-post a>p::after{ 
  content: "";
  bottom: calc(-19  / var(--rem));
  width:  calc(160  / var(--rem));
  height:  calc(1  / var(--rem));
  background-color:   #878787;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.BottomPartGateway .next-post .post { 
  width: 100%;
  position: relative;
  margin:  calc(90  / var(--rem)) 0  0;
}
.BottomPartGateway .next-post .post figure{
    position: relative;
    padding-bottom: 56.4%;
    margin-bottom: 0;
}
.BottomPartGateway .next-post .post img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
}
.BottomPartGateway .next-post .post h1 {
  position: absolute;
  color: #FF3E32;
  font-size:  calc(72  / var(--rem));
  line-height:  calc(109  / var(--rem));
  font-weight: 400;
  font-family: "Anton";
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  bottom: calc(-54  / var(--rem));
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  z-index: 10;
  margin-bottom: 0;
}
.BottomPartGraduate .cohort-part h3{
  position: relative;
}
.BottomPartGraduate .cohort-part h3::after{
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(-32 /var(--rem));
  border-bottom: 0.5px solid #878787;
  width: calc(160 /var(--rem));
}
/* -------------- Responsive -------------- */
@media(min-width:1729px){
  .BottomPartGateway{
    background-color: #000;
    overflow: hidden;
  }
  .BottomPartGateway .donation{
    max-width: 1077px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 300px;
    padding-right: 0;
  }
  .BottomPartGateway .next-post{
    position: relative;
    z-index: 1;
  }
  .BottomPartGateway .next-post::before{
    content: "";
    position: absolute;
    width: 120vw;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: -1;
  }
}
@media(max-width:1199px){
  .BottomPartGraduate .cohort-part h3::after{
    display: none;
  }
  .BottomPartGateway .donation { 
    padding: calc(132 /var(--rem)) calc(90 /var(--rem)) calc(117 /var(--rem)) ;
  }
  .BottomPartGateway .donation h3{
    line-height:  calc(85 /var(--rem));    
    margin-bottom:  calc(64 /var(--rem)); 
  }
  .ButtonSimple {
    margin-top: calc(111 / var(--rem));
  }
  .BottomPartGateway .next-post { 
    padding: calc(59 / var(--rem)) 0 0;
    max-width: calc(596 / var(--rem)); 
  }
  .BottomPartGateway .next-post p{
    font-size: calc(7 / var(--rem)) ;
    line-height: calc(9 / var(--rem)) ;
    margin-bottom: calc(1 / var(--rem)) ;
  }
  .BottomPartGateway .next-post p:last-child { 
    font-size: calc(10 / var(--rem));
    line-height: calc(15 / var(--rem));  
    letter-spacing:calc( 1.5  / var(--rem)) ;
  }
  .BottomPartGateway .next-post a>p::after { 
    bottom: calc(-12 / var(--rem));
    width: calc(103 / var(--rem)); 
  }
  .BottomPartGateway .next-post .post{
    margin: calc(180 / var(--rem)) 0;
  }
  .BottomPartGateway .next-post .post h1{
    font-size: calc(34 / var(--rem))  ;
    line-height: calc(52 / var(--rem));
    bottom: calc(-26 / var(--rem));
  }
}
@media(max-width:600px){
  .BottomPartGateway{
    margin-top: 0;
  }
  .BottomPartGateway .donation {
    padding: calc(126 /var(--rem)) calc(22 /var(--rem)) calc(106 /var(--rem)) calc(35 /var(--rem)); 
   }
  .BottomPartGateway .donation h3 {
    font-size: calc(63 /var(--rem));    
    line-height: calc(70 /var(--rem));    
    margin-bottom: calc(26 /var(--rem)); 
   }
  .BottomPartGateway .donation p { 
    font-size: calc(13 / var(--rem));
    line-height: calc(20 / var(--rem));
    margin-bottom: calc(20 / var(--rem)); 
  }
  .ButtonSimple {
      margin-top: calc(47 / var(--rem));
  }
  .BottomPartGateway .next-post {
    padding: calc(56 / var(--rem)) 0 0;
    max-width: calc(244 / var(--rem));
  }
  .BottomPartGateway .next-post .post {
    margin: calc(195 / var(--rem)) 0;
  }
  .BottomPartGateway .next-post .post h1 {
    font-size: calc(14 / var(--rem));
    line-height: calc(21 / var(--rem));
    bottom: calc(-10.5 / var(--rem));
  }
  .BottomPartGateway .next-post .post figure { 
    padding-bottom: 62.2%; 
}
}