.footer-part {
  background-color: var(--black-color);
  margin-top: calc(80/ var(--rem));
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(89/ var(--rem)) 0 calc(179/ var(--rem)) 0;
  height: auto;
  cursor: default;
}
.footer-part .footer-part-inner{
    max-width: calc(799/ var(--rem));
    padding: 0 calc(15/ var(--rem));
    margin-left: calc(545/ var(--rem));
}
.footer-part h3 {
  font-family: 'Anton'; 
  font-weight: 400;
  font-size: calc(70/ var(--rem));
  line-height: calc(105/ var(--rem));
  letter-spacing: 0.02em; 
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: calc(50/ var(--rem));
}
.footer-part .imgs{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-part .imgs .footer-left-img,
.footer-part .imgs .footer-right-img{
    width: calc(53% - calc(29/ var(--rem)));
}
.footer-part .imgs .footer-left-img{
  max-width:  calc(347/ var(--rem));
}
.footer-part .imgs .footer-right-img{
  max-width:   calc(365/ var(--rem));
}
.footer-part .imgs .footer-left-img figure,
.footer-part .imgs .footer-right-img figure{
  margin-bottom: calc(53/ var(--rem));
}
.footer-part .imgs .footer-left-img img,
.footer-part .imgs .footer-right-img img{
  width: 100%;
}
.footer-part .imgs .footer-right-img{
  margin-top: calc(111/ var(--rem));
}
.footer-part p {
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(18/ var(--rem));
  line-height: calc(32/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #FFFFFF;
  margin: 0;
}
.footer-part p+p{
  margin-top: calc(32/ var(--rem));
}

/* -------------- Responsive -------------- */ 
@media(min-width:1729px){
  .footer-part .footer-part-inner{
    max-width: 783px;
    margin: 0 auto;
  }
}
@media(max-width:1199px){
  .footer-part .footer-part-inner { 
    margin-left: calc(141/ var(--rem));
  }
  .footer-part h3 { 
    margin-bottom: calc(60/ var(--rem));
  }
  .footer-part .imgs .footer-left-img figure, .footer-part .imgs .footer-right-img figure {
    margin-bottom: calc(103  / var(--rem));
  }
  .footer-part { 
    margin-top: calc(8/ var(--rem)); 
    padding: calc(62/ var(--rem)) 0 calc(233/ var(--rem)) 0; 
  } 
}
@media(max-height:768px) and (max-width:1100px) and (orientation : landscape) {
  .footer-part .footer-part-inner{
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:600px){
  .footer-part{
    margin-top: 0;
    padding: 0;
  }
  .footer-part .footer-part-inner{
    margin: 0;
    padding: calc(21/ var(--rem)) calc(34/ var(--rem)) calc(44/ var(--rem)) calc(44/ var(--rem));
  }
  .footer-part h3 {  
    font-size: calc(30/ var(--rem));
    line-height: calc(37/ var(--rem));    
    margin-bottom: calc(39/ var(--rem));
  }
  .footer-part .imgs{
    flex-wrap: wrap;
  }
  .footer-part .imgs .footer-left-img{
    width: calc(249  / var(--rem));
  } 
  .footer-part .imgs .footer-right-img{
    width: calc(273  / var(--rem));
    margin-left: auto;
  }
  .footer-part .imgs .footer-left-img figure, .footer-part .imgs .footer-right-img figure{
    margin-bottom:  calc(20  / var(--rem));
  }
  .footer-part .imgs .footer-right-img{
    margin-top: 0;
    margin-bottom: calc(18  / var(--rem));
  }
  .footer-part p{
    font-size: calc(13  / var(--rem));
    line-height: calc(20  / var(--rem));
  }
  .footer-part p+p{
    margin-top: calc(20  / var(--rem));
  }
}