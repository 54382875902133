.MentorsTable {
  width: 100%;
  height: auto; 
} 
.MentorsTable.Team>p{
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(18 / var(--rem));
  line-height: calc(32 / var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  max-width: calc(783 / var(--rem));
  padding: 0 calc(15 / var(--rem));
  margin: 0 auto calc(98 / var(--rem));
}
.MentorsTable.Team{
  margin-bottom: calc(137 / var(--rem));
}
.MentorsTable.Team>.row{
  margin: 0 !important;
}
.mentor-filter.RegisterForm {
  margin: 0 auto;
  width: calc(783 / var(--rem));
  padding: 0 calc(15 / var(--rem));
  display: flex;
  justify-content: flex-end;
  margin-bottom: calc(73 / var(--rem));
}
.mentor-filter.RegisterForm .form-group.w-240{
  width: calc(224 / var(--rem));
  max-width: 100%;
}
/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .mentor-filter.RegisterForm{
    max-width: calc(783 / var(--rem)); 
  }
}
@media(max-width:600px){
  .MentorsTable.Team>p { 
    font-size: calc(13 / var(--rem));
    line-height: calc(20 / var(--rem)); 
    max-width: calc(365 / var(--rem));
    letter-spacing: 0.1px;
    margin-top: calc(-14 / var(--rem));
  }
  .mentor-filter.RegisterForm{
    max-width: calc(365 / var(--rem));

  }
}