.Carousel {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: var(--white-color);
  padding: calc(110  / var(--rem)) 0 calc(30  / var(--rem)) 0;
}
.Carousel .swiper {
  height: 100%;
  cursor: default;
  width: calc(1447  / var(--rem));
  overflow: visible; 
}
.swiper-slide{ 
  padding: 0 calc(89  / var(--rem));
} 
.swiper-slide>span{
  transition:padding 0.5s ease-in-out !important;
} 
.Carousel .main-swiper .swiper-slide { 
  width: 100% !important;
  position: relative;
  margin: auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.Carousel .main-swiper .swiper-slide .video-player-outer{
  display: inline-block !important;
  width: 100%; 
}
.Carousel .main-swiper .swiper-slide .title { 
  color: #ff3e32; 
  font-family: "Anton", "Arial", sans-serif;
  font-weight: 400;
  text-transform: uppercase;  
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: center;
  z-index: 10;
}
.Carousel .main-swiper .swiper-slide .details {
  font-family: 'Poppins', sans-serif;
  line-height: calc(22  / var(--rem));
  font-size: calc(12  / var(--rem));
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0;
  color: #000;
  width: 100%;
  padding: 0 calc(15  / var(--rem));
  text-transform: uppercase;  
  z-index: 10;
  cursor: default;
  letter-spacing: calc(0.2  / var(--rem));
  max-width: calc(820  / var(--rem));
  min-height: calc(66  / var(--rem));
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.Carousel .main-swiper .swiper-slide .details a {
  text-decoration: none;
  color:#FF3E32 !important; 
  text-transform: uppercase;
  margin: 0;
  display: block;
} 
.Carousel .main-swiper .swiper-slide p.details.details-link{
  min-height: unset !important;
}
.Carousel .main-swiper .swiper-slide p.details.details-link a{
  margin-bottom: 0 !important;
}
.Carousel .main-swiper .swiper-slide a{
  position: relative;
  cursor: none;
  width: 100%;
} 
.Carousel .main-swiper .swiper-slide .sub-title {
  padding: calc(13  / var(--rem)) 0;
  font-size: calc(38  / var(--rem));
  line-height: calc(64  / var(--rem));
  color: #000;
  letter-spacing: calc(2  / var(--rem));
  font-family: "JuliettaMessie";
  text-align: center;
  margin: 0;
  position: relative;
  z-index: 9;
  min-height: calc(90  / var(--rem));
}
.Carousel .main-swiper .swiper-slide .title-outer {
  position: relative;
  z-index: 1;
  width: 100%;
}
.Carousel .main-swiper .swiper-slide .title-outer h1.title {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: calc(73  / var(--rem));
  line-height: calc(109  / var(--rem));
  display: inline-block;
  bottom: unset;
  pointer-events: none;
  margin-bottom: 0;
}
#mouse-pointer {
  position: absolute;
  pointer-events: none;
}
#mouse-pointer  #read-more{
  width: calc(90  / var(--rem));
  height: calc(90  / var(--rem));
  border: 1px solid rgba(255,255,255,0.4); 
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 99;
  border-radius: 50%;
  color: #ffff; 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  font-family: "Anton"; 
  font-size: calc(13  / var(--rem));
  line-height: calc(21  / var(--rem));
  letter-spacing: calc(2.1  / var(--rem));
  text-transform: uppercase;
  font-weight: 400; 
}
.Carousel .main-swiper .swiper-slide:not(.swiper-slide-active) p.details{
    opacity: 0;
}
.Carousel .main-swiper .swiper-slide .details a{
  cursor: pointer !important;
}
.cs-video-outer{
  padding-top: 0 !important;
}
.cs-video-outer .modal-dialog.video-inner,
.cs-video-outer .modal-dialog.video-inner .modal-body{
  padding: 0;
}
.cs-video-outer .modal-dialog.video-inner .video-wrap {
  position: relative;
  padding-bottom: 56%;
}
.cs-video-outer .modal-dialog.video-inner {
  width: 1550px !important;
  max-width: calc(100% - 30px);
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.cs-video-outer .modal-dialog.video-inner .modal-content{
  max-width: 160vh;
  margin: 0 auto;
}
.cs-video-outer .modal-dialog.video-inner .video-wrap video{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  left: 0;
  top: 0;
  bottom: -5px;
  right: 0;
}
.fade.cs-video-outer.modal.show {
  z-index: 9999;
}
@media(min-width:1200px){
  .Carousel .main-swiper .swiper-slide .video-player-outer,
  .Carousel .main-swiper .swiper-slide .video-player-outer video{
    height: 63.8vh;
    max-height: calc(712  / var(--rem));
    transition: 0.5s ease-in-out;
  } 
.Carousel .main-swiper .swiper-slide:not(.swiper-slide-active) .video-player-outer, .Carousel .main-swiper .swiper-slide:not(.swiper-slide-active) .video-player-outer video{
    height: calc(63.8vh - calc(32  / var(--rem)));
    max-height:    calc(680 / var(--rem));;
  } 
}
/* -------------- Responsive -------------- */  
@media(min-width:1729px){
  .Carousel .swiper {
    width: calc(100% - 260px);
  }
  .Carousel .main-swiper .swiper-slide .outer-wrap{
    width: 1225px;
    margin: 0 auto;
  }
  .Carousel .main-swiper .swiper-slide.swiper-slide-prev   .outer-wrap{
    justify-content: flex-end;
    margin-right: 0;
  }
  .Carousel .main-swiper .swiper-slide.swiper-slide-next  .outer-wrap{
    justify-content: flex-start;
    margin-left: 0;
  }
}
@media(max-width:1199px){ 
  .Carousel .swiper { 
    width: calc(793  / var(--rem)); 
  }
  .swiper-slide{ 
    padding: 0 calc(50  / var(--rem));
  }   
  .Carousel .main-swiper .swiper-slide .details {
    line-height: calc(23 / var(--rem));
    font-size: calc(14 / var(--rem));
    padding: 0;
    max-width: 100%;
    min-height: calc(110/ var(--rem));
    -webkit-line-clamp: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } 
  .Carousel .main-swiper .swiper-slide .sub-title {
    padding: calc(10  / var(--rem)) 0  calc(70  / var(--rem)) ;
    font-size: calc(27  / var(--rem));
    line-height: calc(46  / var(--rem)); 
    letter-spacing: calc(2  / var(--rem)); 
    min-height: calc(126  / var(--rem));
  } 
  .Carousel .main-swiper .swiper-slide .title-outer h1.title { 
    font-size: calc(52  / var(--rem));
    line-height: calc(79  / var(--rem)); 
  } 
  #mouse-pointer  #read-more{
    width: calc(90  / var(--rem));
    height: calc(90  / var(--rem));
    border: 1px solid rgba(255,255,255,0.4);  
    font-size: calc(13  / var(--rem));
    line-height: calc(21  / var(--rem));
    letter-spacing: calc(2.1  / var(--rem)); 
  } 
  .Carousel .main-swiper .swiper-slide .video-player-outer,
  .Carousel .main-swiper .swiper-slide .video-player-outer video{
    height:calc(389  / var(--rem)) ;
    max-height: 63.8vh;
    transition: 0.5s ease-in-out;
  }  
.Carousel .main-swiper .swiper-slide:not(.swiper-slide-active) .video-player-outer, .Carousel .main-swiper .swiper-slide:not(.swiper-slide-active) .video-player-outer video{
  max-height: calc(63.8vh - calc(32  / var(--rem)));
  height:    calc(349 / var(--rem));;
}
} 

@media(max-height:768px) and (max-width:1100px) and (orientation : landscape) {
  .Carousel .main-swiper .swiper-slide .video-player-outer, .Carousel .main-swiper .swiper-slide .video-player-outer video{
    height: 40vh !important;
  }
  .Carousel .main-swiper .swiper-slide .sub-title {
    padding: calc(10 / var(--rem)) 0 calc(15 / var(--rem));
    letter-spacing: calc(2 / var(--rem));
    min-height: calc(72 / var(--rem));
  }
}
@media(max-width:600px){
  .Carousel .swiper {
    width: calc(331/ var(--rem));
  }
  .swiper-slide {
    padding: 0 calc(20 / var(--rem));
  }
  .Carousel .main-swiper .swiper-slide .video-player-outer, .Carousel .main-swiper .swiper-slide .video-player-outer video {
    height: calc(163 / var(--rem));
    max-height: 50vh;
    transition: 0.5s ease-in-out;
  } 
.Carousel .main-swiper .swiper-slide:not(.swiper-slide-active) .video-player-outer, .Carousel .main-swiper .swiper-slide:not(.swiper-slide-active) .video-player-outer video{
  max-height: calc(50vh - calc(16  / var(--rem)));
  height:    calc(147 / var(--rem));;
}
  .Carousel .main-swiper .swiper-slide .title-outer h1.title {
    font-size: calc(31 / var(--rem));
    line-height: calc(46 / var(--rem));
  }
  .Carousel .main-swiper .swiper-slide .sub-title {
    padding: calc(3 / var(--rem)) 0 calc(56 / var(--rem));
    font-size: calc(16 / var(--rem));
    line-height: calc(27 / var(--rem));
    letter-spacing: calc(1 / var(--rem));
    min-height: calc(86 / var(--rem));
  } 
  .Carousel .main-swiper .swiper-slide .details {
    line-height: calc(20 / var(--rem));
    font-size: calc(11 / var(--rem));
    min-height: calc(209 / var(--rem));
    -webkit-line-clamp: 7;
    margin-left: calc(-25 / var(--rem));
    width: calc(100% + calc(50 / var(--rem)));
    max-width: calc(100% + calc(50 / var(--rem)));
    justify-content: flex-start;
  }
  .Carousel .main-swiper .swiper-slide .details a{
    margin-bottom:calc(50 / var(--rem)) ;
  }
}