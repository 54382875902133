/* Global */
div[class$="Form"] .mb-40{
  margin-bottom: calc(40 / var(--rem));
}
div[class$="Form"] .mb-70{
  margin-bottom:calc(70 / var(--rem));
}
.ContactForm,
.RegisterForm,
.SponsorForm,
.SubscribeForm {
  width: 100%;
  height: auto;
}
.SponsorForm form .row {
  margin-bottom: calc(30 / var(--rem)) !important;
}
.SponsorForm form .row div textarea {
  margin-top: calc(60 / var(--rem)) !important;
  margin-bottom: calc(30 / var(--rem)) !important;
} 
.form-row>div{
  max-width: calc(246 / var(--rem));
} 
.form-group.w-240{
  max-width: calc(240 / var(--rem)) !important;
} 
/* Text input and textarea */
.ContactForm .form-group,
.RegisterForm .form-group,
.SponsorForm .form-group,
.SubscribeForm .form-group {
  margin-bottom: calc(11 / var(--rem));
}
.ContactForm .form-control,
.RegisterForm .form-control,
.SponsorForm .form-control,
.SubscribeForm .form-control {
  border: none;
  border-bottom: 1px solid #878787;
  border-radius: 0; 
  transition: all 0.4s ease-in-out;
  font-size: calc(18 / var(--rem));
  line-height: calc(17 / var(--rem));
  color: #878787;
  padding: calc(19 / var(--rem)) 0;
  font-family: 'Barlow'; 
}
.ContactForm .form-control:focus,
.RegisterForm .form-control:focus,
.SponsorForm .form-control:focus,
.SubscribeForm .form-control:focus {
  box-shadow: none !important;
  border-bottom: 1px solid var(--brouwn-color);
  transition: all 0.4s ease-in-out;
}
.ContactForm .form-control::-webkit-input-placeholder,
.RegisterForm .form-control::-webkit-input-placeholder,
.SponsorForm .form-control::-webkit-input-placeholder,
.SubscribeForm .form-control::-webkit-input-placeholder {
  color: var(--input-grey-color);
  font-style: italic;
  text-transform: initial;
}
.ContactForm textarea.form-control,
.RegisterForm textarea.form-control,
.SponsorForm textarea.form-control,
.SubscribeForm textarea.form-control{
  margin-top: calc(37 / var(--rem));
}
/* Select option */
.ContactForm .select-input,
.RegisterForm .select-input,
.SponsorForm .select-input,
.SubscribeForm .select-input {
  font-style: italic;
  text-transform: capitalize;
}
.ContactForm .select-input .select__control,
.RegisterForm .select-input .select__control,
.SponsorForm .select-input .select__control,
.SubscribeForm .select-input .select__control { 
  border: none;
  border-bottom: 1px solid #878787;
  border-radius: 0; 
  transition: all 0.4s ease-in-out;
  font-size: calc(18 / var(--rem));
  line-height: calc(17 / var(--rem));
  color: #878787;
  padding: calc(19 / var(--rem)) 0;
  font-family: 'Barlow'; 
  min-height: unset;
}
.ContactForm .select-input .select__control.select__control--is-focused,
.RegisterForm .select-input .select__control.select__control--is-focused,
.SponsorForm .select-input .select__control.select__control--is-focused,
.ContactForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open,
.RegisterForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open,
.SponsorForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open,
.SubscribeForm
  .select-input
  .select__control.select__control--is-focused.select__control--menu-is-open {
  box-shadow: none;
}
.ContactForm .select-input .select__control .select__value-container,
.RegisterForm .select-input .select__control .select__value-container,
.SponsorForm .select-input .select__control .select__value-container,
.SubscribeForm .select-input .select__control .select__value-container,
.ContactForm .select-input .css-tlfecz-indicatorContain,
.RegisterForm .select-input .css-tlfecz-indicatorContain,
.SponsorForm .select-input .css-tlfecz-indicatorContain,
.SubscribeForm .select-input .css-tlfecz-indicatorContainer {
  padding: 0; 
}
.ContactForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator,
.RegisterForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator,
.SponsorForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator,
.SubscribeForm
  .select-input
  .select__control
  .select__indicators
  .select__indicator-separator {
  display: none;
}
.ContactForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator,
.RegisterForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator,
.SponsorForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator,
.SubscribeForm
  .select-input
  .select__control
  .select__indicators
  .select__clear-indicator {
  margin-right: calc(-10 / var(--rem));
}
/* => Menu select option */
.ContactForm .select-input .select__menu,
.RegisterForm .select-input .select__menu,
.SponsorForm .select-input .select__menu,
.SubscribeForm .select-input .select__menu {
  border-color: #c4c4c4;
  border-radius: 0;
  padding: 0;
  color: var(--input-grey-color);
}
.ContactForm .select-input .select__menu .select__menu-list,
.RegisterForm .select-input .select__menu .select__menu-list,
.SponsorForm .select-input .select__menu .select__menu-list,
.SubscribeForm .select-input .select__menu .select__menu-list {
  padding: 0;
}
.ContactForm .select-input .select__menu .select__menu-list .select__option,
.RegisterForm .select-input .select__menu .select__menu-list .select__option,
.SponsorForm .select-input .select__menu .select__menu-list .select__option,
.SubscribeForm .select-input .select__menu .select__menu-list .select__option {
  cursor: pointer;
  border-bottom: 1px solid #c4c4c4;
  position: relative;
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:last-child {
  border-bottom: none;
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option:hover:before {
  content: "";
  position: absolute;
  left: 0;
  height: 80%;
  width: calc(4 / var(--rem));
  margin-top: calc(-3 / var(--rem));
  background-color: var(--brouwn-color);
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-focused {
  background-color: transparent;
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected {
  background-color: transparent;
  color: var(--brouwn-color);
}
.ContactForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before,
.RegisterForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before,
.SponsorForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before,
.SubscribeForm
  .select-input
  .select__menu
  .select__menu-list
  .select__option.select__option--is-selected:before {
  content: "";
  position: absolute;
  left: 0;
  height: 80%;
  width: calc(4 / var(--rem));
  margin-top: calc(-3 / var(--rem));
  background-color: var(--brouwn-color);
}
.css-tlfecz-indicatorContainer{
  padding: 0 !important;
}
.RegisterForm .select-input .select__control * {
  padding: 0 !important;
}
@media(max-width:1199px){
  .form-group.w-240 {
    max-width: calc(200/ var(--rem)) !important;
  } 
  .ContactForm .form-control, .RegisterForm .form-control, .SponsorForm .form-control, .SubscribeForm .form-control { 
    font-size: calc(16 / var(--rem));
    line-height: calc(16 / var(--rem)); 
    padding: calc(17 / var(--rem)) 0; 
  }
  .ContactForm .select-input .select__control, .RegisterForm .select-input .select__control, .SponsorForm .select-input .select__control, .SubscribeForm .select-input .select__control{
    padding: calc(17 / var(--rem)) 0; 
  }
  div[class$="Form"] .mb-40 {
    margin-bottom: calc(35 / var(--rem));
  }
  div[class$="Form"] .mb-70 {
    margin-bottom: calc(65 / var(--rem));
  }
  .ContactForm .select-input .select__control ~ .select__menu *, 
  .RegisterForm .select-input .select__control ~ .select__menu *, 
  .SponsorForm .select-input .select__control ~ .select__menu *, 
  .SubscribeForm .select-input .select__control ~ .select__menu *{
      font-size: 12px !important;
  }
}
@media(max-width:600px){
  .ContactForm .form-control, .RegisterForm .form-control, .SponsorForm .form-control, .SubscribeForm .form-control {
    font-size: calc(12 / var(--rem));
    line-height: calc(12 / var(--rem));
    padding: calc(12 / var(--rem)) 0;
  }
  .ContactForm .select-input .select__control, .RegisterForm .select-input .select__control, .SponsorForm .select-input .select__control, .SubscribeForm .select-input .select__control{
    padding: calc(12 / var(--rem)) 0;
    font-size: calc(12 / var(--rem));
    line-height: calc(12 / var(--rem));
  }
  .form-group.w-240 {
    max-width: calc(138 / var(--rem)) !important;
  }
  .ContactForm textarea.form-control, .RegisterForm textarea.form-control, .SponsorForm textarea.form-control, .SubscribeForm textarea.form-control {
    margin-top: calc(29 / var(--rem));
  }
  div[class$="Form"] .mb-40 {
    margin-bottom: calc(25 / var(--rem));
  }
  .Register .content .infos,
  .Register .content .form{
    padding: 0;
  }
}