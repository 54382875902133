.Error404 {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: default;
  padding: calc(40 / var(--rem));
} 
.Error404 h1 {  
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: calc(158  / var(--rem));
  line-height: calc(239 / var(--rem));
  text-align: center;
  letter-spacing: 0.02em; 
  color: #FF3E32;
  text-align: center;
  margin-bottom: calc(-20 / var(--rem));
}
.Error404 h6 {
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(32 / var(--rem));
  line-height: calc(38 / var(--rem));
  text-align: center;
  letter-spacing: 0.02em; 
  color: #000000; 
  margin-bottom: calc(18 / var(--rem));
}
.Error404 p {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: calc(18 / var(--rem));
  line-height: calc(32 / var(--rem)); 
  text-align: center;
  letter-spacing: 0.02em; 
  color: #000000; 
  margin-bottom: 0;
}
.Error404 .ButtonSimple {
  margin-top: calc(94 / var(--rem));
}
.Error404 .ButtonSimple a,
.Error404 .ButtonSimple a:focus,
.Error404 .ButtonSimple a:active:focus {
  background-color: var(--brouwn-color);
  text-transform: capitalize;
  border-color: var(--brouwn-color); 
}
.Error404 .ButtonSimple:hover a {
  border-color: var(--white-color);
}
@media(max-width:1199px){
  .Error404 h1{
    font-size: calc(135  / var(--rem));
    line-height: calc(203 / var(--rem));
  }
  .Error404 h6 { 
    font-size: calc(27 / var(--rem));
    line-height: calc(32 / var(--rem)); 
    margin-bottom: calc(14 / var(--rem));
  }
  .Error404 p { 
    font-size: calc(15 / var(--rem));
    line-height: calc(27 / var(--rem)); 
  }
  .Error404 .ButtonSimple {
    margin-top: calc(80 / var(--rem));
  }
} 
@media(max-height:768px) and (max-width:1100px) and (orientation : landscape) {
  .Error404 .ButtonSimple {
    margin-top: calc(40 / var(--rem));
  }
  .Error404 h1 {
    font-size: calc(97 / var(--rem));
    line-height: calc(140 / var(--rem));
  }
}
@media(max-width:600px){
  .Error404 h1{
    font-size: calc(88  / var(--rem));
    line-height: calc(133 / var(--rem));
  }
  .Error404 h6 { 
    font-size: calc(17 / var(--rem));
    line-height: calc(21 / var(--rem)); 
    margin-bottom: calc(10 / var(--rem));
  }
  .Error404 p { 
    font-size: calc(10 / var(--rem));
    line-height: calc(17 / var(--rem)); 
  }
  .Error404 .ButtonSimple {
    margin-top: calc(52 / var(--rem));
  }
} 