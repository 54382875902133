.Contact {
  width: 100%;
  height: auto;
  margin-bottom: calc(178 / var(--rem));
} 
.Contact .content {
  padding: 0 calc(257 / var(--rem)) 0 calc(288 / var(--rem));
  margin-top: calc(17 / var(--rem));
  display: inline-flex;
  width: 100%;
}
/* Contact Infos */
.Contact .content .infos,
.Contact .content .form {
  display: flex;
  flex-direction: column;
  justify-content: start-flex;
  align-items: start-flex;
  cursor: default;
}
.Contact .content .form{
  max-width: calc(525 / var(--rem));
}
.Contact .content .infos h4, 
.Contact .content .form h4 {
  font-size: calc(33 / var(--rem));
  line-height:  calc(23 / var(--rem));
  font-weight: 600; 
  font-family: 'Barlow';
  color: var(--brouwn-color);
  text-transform: uppercase;
  letter-spacing:  calc(1 / var(--rem));
  margin-bottom:  calc(30 / var(--rem));
}
.Contact .content .infos h5 {
  margin-top:  calc(30 / var(--rem));
} 
.Contact .content .infos .coordinates{
  margin-bottom:  calc(66 / var(--rem));
  padding-left: 0%;
}
.Contact .content .infos .coordinates li a {
  font-size:  calc(21 / var(--rem));
  line-height:  calc(24.5 / var(--rem));
  color: var(--black-color);
  font-weight: 600;
  font-family: 'Barlow';
  transition: all 0.4s ease-in-out;
  letter-spacing: calc( 1 / var(--rem));
  text-transform: uppercase;
}
.Contact .content .infos h5{
  font-size:  calc(21 / var(--rem));
  line-height: calc( 14 / var(--rem));
  color: var(--black-color); 
  font-family: 'Barlow';
  letter-spacing: calc( 1 / var(--rem));
  margin-bottom:  calc(30 / var(--rem));
  margin-top: 0;
  text-transform: uppercase;
  font-weight: 600;
}
.Contact .content .infos .opening-hours li{
  font-size:  calc(20 / var(--rem));
  line-height: 1;
  color: var(--black-color);
  font-weight: 500;
  font-family: 'Barlow'; 
  letter-spacing:  calc(1 / var(--rem));
  margin-bottom:  calc(10 / var(--rem));
  transition: all 0.4s ease-in-out;
}
.Contact .content .infos .coordinates li a:hover {
  color: var(--brouwn-color) !important;
}
.Contact .content .infos .opening-hours {
  padding: 0;
  font-weight: 400;
  font-size:  calc(18 / var(--rem));
  letter-spacing: 0.02em;
} 
/* Contact Form */
.Contact .content .form {
  margin-top: 0;
} 
/* -------------- Responsive -------------- */ 
@media(min-width:1729px){
  .Contact .content{
    max-width: 1182px;
    margin: 0 auto;
    display: flex;
    padding: 0;
  }
  .Contact .content .infos, .Contact .content .form{
    padding: 0 10px;
  }
}
@media(max-width:1199px){
  .Contact .content {
    padding: 0 calc(34 / var(--rem)) 0 calc(62 / var(--rem));
    margin-top: calc(20 / var(--rem)); 
  }
  .Contact .content .infos h4, .Contact .content .form h4 {
    font-size: calc(30 / var(--rem));
    line-height: calc(15 / var(--rem)); 
  }
  .Contact .content .infos h5,
  .Contact .content .infos .coordinates li a {
    font-size: calc(18 / var(--rem));
    line-height: calc(22 / var(--rem));
  }
  .Contact .content .infos .coordinates {
    margin-bottom: calc(66 / var(--rem)); 
  }
  .Contact .content .infos h5{
    margin-bottom: calc(26 / var(--rem)); 
  }
  .Contact .content .infos .opening-hours li {
    font-size: calc(17 / var(--rem));
  }
  .Contact .content .infos, .Contact .content .form{
    flex: unset;
  }
  .Contact .content .form {
    max-width: calc(507 / var(--rem));
  }
  .Contact .content .infos {
    width:calc(420 / var(--rem));
  }
}
@media(max-width:600px){
  .Contact{
    margin-bottom: 0;
  }
  .Contact .content {
    padding: calc(5 / var(--rem)) calc(48 / var(--rem))  calc(45 / var(--rem)) calc(26 / var(--rem));
    margin-top: 0
  }
  .Contact .content .infos h4  {
    font-size: calc(23 / var(--rem));
    line-height: calc(16 / var(--rem));
    margin-bottom: calc(21 / var(--rem));
  }
  .Contact .content .infos .coordinates{
    margin-bottom: calc(45 / var(--rem));
  }
  .Contact .content .infos .coordinates li a {
    font-size: calc(15 / var(--rem));
    line-height: calc(17.5 / var(--rem));
  }
  .Contact .content .infos h5 {
    font-size: calc(14 / var(--rem));
    line-height: calc(10 / var(--rem));
    margin-bottom: calc(20 / var(--rem));
  }
  .Contact .content .infos .opening-hours{
    margin-bottom:calc(55 / var(--rem)) ;
  }
  .Contact .content .infos .opening-hours li{ 
    font-size: calc(14 / var(--rem)); 
    line-height: calc(18.5 / var(--rem)); 
    margin-bottom: 0;
  }
  .Contact .content .form h4 {
    font-size: calc(21 / var(--rem));
    line-height: calc(14 / var(--rem));
    margin-bottom:calc(20 / var(--rem)) ;
  }
  .Contact  .form-group.w-240 {
    max-width: calc(145 / var(--rem)) !important;
  }
}