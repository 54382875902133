.LeftPartBlog {
  height: auto;
  padding: 0; 
} 
/* First Part */
.LeftPartBlog .part-1 {
  margin-bottom: calc(223/ var(--rem));
}
.LeftPartBlog .part-1 h4 {
  text-transform: capitalize;
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(32/ var(--rem));
  line-height: calc(38/ var(--rem));
  letter-spacing: 0.02em; 
  color: #000000;
  margin-bottom: calc(23/ var(--rem));
} 
.LeftPartBlog .part-1 h6 {
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(15/ var(--rem));
  line-height: calc(18/ var(--rem));
  letter-spacing: 0.02em; 
  color: #000000;
}
.LeftPartBlog .part-1 p {
  text-decoration: underline !important;
  font-family: 'Barlow'; 
  font-weight: 600;
  font-size: calc(20/ var(--rem));
  line-height: calc(24/ var(--rem));
  letter-spacing: 0.02em; 
  color: #CDAB77;
  cursor: pointer;
  margin-top: calc(84/ var(--rem));
  margin-bottom: 0;
}
.LeftPartBlog .part-1 p:hover {
  text-decoration: none !important;
  transition: all 0.4s ease-in-out;
}

/* Second Part */
.LeftPartBlog .part-2 h6 {
  color: var(--black-color);
  font-weight: 600;
  margin:0 0 calc(5/ var(--rem)) ;
  font-family: 'Barlow'; 
  font-weight: 600;
  font-size: calc(20/ var(--rem));
  line-height: calc(24/ var(--rem)); 
  
  letter-spacing: 0.02em;
  
  color: #000000;
}
.LeftPartBlog .part-2 ul {
  margin: 0;
  padding: 0;
}
.LeftPartBlog .part-2 ul li a { 
  text-decoration: underline !important;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  font-size: calc(20/ var(--rem));
  line-height: calc(24/ var(--rem)); 
  letter-spacing: 0.02em;  
  color: #CDAB77 !important;
  margin-top: calc(15/ var(--rem));
  transition: all 0.4s ease-in-out;
  display: inline-block;
}
.LeftPartBlog .part-2 ul li:hover a {
  text-decoration: none !important;
}

/* Modal Part */
.social-share .modal-dialog .modal-content .modal-body p {
  margin-bottom: calc(20/ var(--rem));
}
.social-share
  .modal-dialog
  .modal-content
  .modal-body
  .react-share__ShareButton {
  filter: grayscale(0);
  transition: 0.2s ease-in-out;
}
.social-share
  .modal-dialog
  .modal-content
  .modal-body
  .react-share__ShareButton:hover {
  filter: grayscale(1);
  transform: scale(1.1);
  transition: 0.2s ease-in-out;
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .LeftPartBlog .part-1 p{
    margin-top: calc(54 / var(--rem));
  }
  .LeftPartBlog .part-1{
    margin-bottom:calc(95 / var(--rem)) ;
  }
  .part-2{
    margin-top: calc(40 / var(--rem));
  }
}
@media(max-width:600px){
  .LeftPartBlog .part-1 h4 { 
    font-size: calc(25/ var(--rem));
    line-height: calc(30/ var(--rem)); 
    margin-bottom: calc(18/ var(--rem));
  }
  .LeftPartBlog .part-1 h6 { 
    font-size: calc(12/ var(--rem));
    line-height: calc(14/ var(--rem)); 
    margin-bottom: 0;
  }
  .LeftPartBlog .part-1 p {
    margin-top: calc(32 / var(--rem));
    font-size: calc(16/ var(--rem));
    line-height: calc(19/ var(--rem));
  }
  .LeftPartBlog .part-1 {
    margin-bottom: calc(84 / var(--rem));
  }
  .LeftPartBlog .part-2 h6 { 
    margin: 0; 
    font-size: calc(15/ var(--rem));
    line-height: calc(19/ var(--rem)); 
  }
  .LeftPartBlog .part-2 ul li a { 
    font-size: calc(15/ var(--rem));
    line-height: calc(19/ var(--rem)); 
    margin-top: calc(11/ var(--rem)); 
  }
  .mob-left{
    margin-bottom:calc(62/ var(--rem));
  }
}
