.Partners {
  width: 100%;
  height: auto;
}
.Partners .container-list {
  width: 100%;
  max-width: calc(783 / var(--rem));
  padding: calc(50 / var(--rem)) calc(15 / var(--rem)) calc(90 / var(--rem));
  margin: 0 auto;
  cursor: default;
}
.Partners .container-list p {
  font-size: calc(20 / var(--rem));
  line-height: calc(32 / var(--rem));
  font-weight: 300;
  letter-spacing: calc(0.3 / var(--rem));
  margin-bottom: calc(140 / var(--rem));
  font-family: 'Barlow';
  color: #000;
}
.Partners .container-list ul {
  margin: 0;
  padding: 0;
}
.Partners .container-list ul li {
  margin: 0;
  padding: calc(20 / var(--rem)) 0;
  border-bottom: 1px solid var(--divider-grey-color);
  min-height: calc(112 / var(--rem));
  display: flex;
  align-items: center;
}
.Partners .container-list ul li>.row{
  flex-grow: 1;
}
.Partners .container-list ul li .logo {
  width: calc(220/ var(--rem));
  height: calc(58 / var(--rem));
  position: relative;
  margin: 0 auto;
  max-width: 100%;
}
.Partners .container-list ul li .logo img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.Partners .container-list ul li h6 {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: calc(20 / var(--rem));
  line-height: calc(24 / var(--rem));
  font-weight: 400;
  letter-spacing: calc(0.3 / var(--rem));
  font-family: 'Barlow';
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  padding-left: calc(15 / var(--rem));
  color: #000;
} 
.Partners .container-list .ButtonSimple {
  text-align: center;
  margin-top: calc(111 / var(--rem));
}
.Partners .container-list .ButtonSimple button,
.Partners .container-list .ButtonSimple button:focus,
.Partners .container-list .ButtonSimple button:active:focus {
  background-color: var(--brouwn-color);
  text-transform: capitalize;
  border-color: var(--brouwn-color);
}
.Partners .container-list .ButtonSimple:hover button {
  border-color: var(--white-color);
}
/*********************** Modal *************************/
.modal.fade .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
}
.modal.fade .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}
.modal.fade .modal-dialog .modal-content .modal-header button:focus {
  box-shadow: none;
}
.cs-sponsor-modal .cs-sponsor-width{
  width: 100%;
  max-width: calc(966/ var(--rem));
  margin-top: 0;
  margin-bottom: 0;
} 
.cs-sponsor-modal .cs-sponsor-width .modal-body{
  padding: calc(105/ var(--rem)) calc(141/ var(--rem)) calc(101/ var(--rem)) calc(127/ var(--rem));
}
.cs-sponsor-modal .cs-sponsor-width  .SponsorForm form .row{
  margin-bottom:  calc( 40/ var(--rem)) !important;
}
.cs-sponsor-modal .cs-sponsor-width  .SponsorForm form .row textarea{
  margin-top:  calc( 150/ var(--rem)) !important;
}
.cs-sponsor-modal .cs-sponsor-width .SponsorForm form .ButtonSubmit {
  margin-top: calc(28/ var(--rem));
  display: inline-flex;
}
/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .Partners .container-list { 
    max-width: 100%;
    padding: calc(25 / var(--rem)) calc(128 / var(--rem)) calc(78 / var(--rem)) calc(143 / var(--rem)); 
  }
  .Partners .container-list .ButtonSimple {
    margin-top: calc(147 / var(--rem));
  }
}
@media(max-width:600px){
  .Partners .container-list { 
    padding: calc(6 / var(--rem)) calc(23 / var(--rem)) calc(55 / var(--rem));
  }
  .Partners .container-list p {
    font-size: calc(13 / var(--rem));
    line-height: calc(20 / var(--rem)); 
    margin-bottom: calc(60 / var(--rem)); 
  }
  .Partners .container-list ul li .row> .col-lg-4{
    width: calc(123 / var(--rem));
  }
  .Partners .container-list ul li .row> .col-lg-4+div{
    width:  calc((100% - calc(123/ var(--rem)))) ;
  }
  .Partners .container-list ul li{
    padding: calc(12 / var(--rem)) 0;
  }
  .Partners .container-list ul li h6 { 
    font-size: calc(13 / var(--rem));
    line-height: calc(16 / var(--rem));
    padding-left: calc(27 / var(--rem));
  }
  .Partners .container-list ul li .logo { 
    height: calc(38/ var(--rem)); 
  }
  .Partners .container-list .ButtonSimple {
    margin-top: calc(78 / var(--rem));
  }
  .cs-sponsor-modal .cs-sponsor-width{
    width: calc(365/ var(--rem));
    margin:0 auto 0 ;
  }
  .cs-sponsor-modal .cs-sponsor-width .modal-body {
    padding: calc(62/ var(--rem)) calc(47/ var(--rem)) calc(50/ var(--rem)) calc(38/ var(--rem));
  }
  .cs-sponsor-modal .cs-sponsor-width .SponsorForm form .row {
    margin-bottom: calc( 32/ var(--rem)) !important;
  }
  .cs-sponsor-modal .cs-sponsor-width .SponsorForm form .row textarea {
    margin-top: calc( 54/ var(--rem)) !important;
  }
  
}