.ButtonSubmit {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ButtonSubmit button,
.ButtonSubmit button:active,
.ButtonSubmit button:focus,
.ButtonSubmit button:active:focus {
  background-color:#CDAB77;
  border: 0.569383px solid #CDAB77;
  padding: calc(14 / var(--rem)) calc(40 / var(--rem));
  text-align: center;
  color: var(--black-color);
  font-size: calc(18 / var(--rem));
  line-height: calc(21 / var(--rem));
  text-align: center;
  font-weight: 600;
  font-family : "Barlow"; 
  position: relative; 
  border-radius: 0;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
  min-width: calc(272 / var(--rem));
}
.ButtonSubmit button:hover,
.ButtonSubmit button:hover:active,
.ButtonSubmit button:hover:focus,
.ButtonSubmit button:hover:active:focus {
  color: var(--black-color);
  background-color: var(--brouwn-color);
  border-color:var(--white-color);
  transition: all 0.4s ease-in-out;
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .ButtonSubmit button, .ButtonSubmit button:active, .ButtonSubmit button:focus, .ButtonSubmit button:active:focus{ 
    padding: calc(12 / var(--rem)) calc(29 / var(--rem)); 
    font-size: calc(16 / var(--rem));
    line-height: calc(19 / var(--rem)); 
    min-width: calc(242 / var(--rem));
  }
}
@media(max-width:600px){
  .ButtonSubmit button, .ButtonSubmit button:active, .ButtonSubmit button:focus, .ButtonSubmit button:active:focus{ 
    padding: calc(7 / var(--rem)) calc(15 / var(--rem));
    font-size: calc(11 / var(--rem));
    line-height: calc(14 / var(--rem));
    min-width: calc(147/ var(--rem));
    border: none;
  }
}