.Team {
  width: 100%;
  height: auto; 
}
.Team .BgView img {
  object-fit: cover;
  object-position: top;
}
/* Cards list */
.Team .list-team {
  padding: 0;
  width: 100%;
  max-width:  calc(783 / var(--rem)); 
  padding: 0 calc(15 / var(--rem));
  /* margin: 0 0 0 calc(478 / var(--rem)); */
  margin: 0 auto;
  cursor: default;
}
.Team .list-team .member-team {
  margin-bottom: calc(40 / var(--rem));
  height: auto;
  border-bottom: 1px solid var(--divider-grey-color);
  padding-bottom: calc(16 / var(--rem));
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
} 
.Team .list-team .member-team .member-img{
  width: calc(117 / var(--rem));
}
.Team .list-team .member-team .member-img figure{
  width: 100%;
  padding-bottom: 119.7%;
} 
.Team .list-team .member-team  .member-details{
  width: calc(100% - calc(117 / var(--rem)));
  padding-left: calc(51 / var(--rem));
}
.Team .list-team .member-team  .member-details h6{
  font-size: calc(28 / var(--rem));
  line-height: calc(33 / var(--rem));
  font-weight: 600;
  font-family: 'Barlow';
  color: #000;
  margin-bottom: calc(2 / var(--rem));
} 
.Team .list-team .member-team  .member-details p{
  font-size: calc(15 / var(--rem));
  line-height: calc(20 / var(--rem));
  font-weight: 400;
  font-family: 'Barlow';
  color: #878787;
  margin-bottom: calc(9 / var(--rem));
  max-width: calc(272 / var(--rem));
}
.Team .list-team .member-team  .member-details .member-info  p{
  min-height: calc(40 / var(--rem));
}
.Team .list-team .member-team  .member-details .social a{
  color: #878787 !important;
  margin: 0 calc(13 / var(--rem)) calc(13 / var(--rem)) 0;
  line-height: calc(12 / var(--rem));
}
.Team .list-team .member-team  .learn-more p{
  margin-top: calc(8 / var(--rem));
  margin-bottom: 0;
}
.Team .list-team .member-team  .learn-more p.more-infos{
  font-size: calc(13 / var(--rem));
  line-height: calc(15 / var(--rem));
  font-weight: 600;
  font-family: 'Barlow';
  color:  #CDAB77;
  margin-bottom: 0;  
}
/*********************************** Modal ***********************************/
.cs-team-modal .modal-dialog.cs-team-width{
  max-width: calc(966/ var(--rem));
}
.cs-team-modal .cs-team-width  .modal-body {
  cursor: default; 
  padding: calc(70/ var(--rem)) calc(55/ var(--rem));
}
.cs-team-modal .cs-team-width  .modal-body .team-details{
  padding: 0 calc(15/ var(--rem));
}
.cs-team-modal .cs-team-width  .modal-body .team-details .col{
  flex: unset;
}
.cs-team-modal  .team-details .picture-part{
  width:37.8%;
}
.cs-team-modal  .team-details .picture-part figure,
.cs-team-modal  .team-details .picture-part figure img{
  width: 100%;
  margin-bottom: 0;
}
.cs-team-modal  .team-details .infos-part{
  width:62.2%;
  padding-left: calc(27/ var(--rem));
  padding-right: 0;
}
.cs-team-modal  .team-details .infos-part h4{
  font-family: 'Barlow'; 
  font-weight: 600;
  font-size: calc(35/ var(--rem));
  line-height: calc(43/ var(--rem));
  letter-spacing: 0.02em; 
  color: #202020;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: calc(11/ var(--rem));
}
.cs-team-modal  .team-details .infos-part  p,
.cs-team-modal  .team-details .infos-part  ul li {
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(15/ var(--rem));
  line-height: calc(20/ var(--rem)); 
  text-align: justify;
  letter-spacing: 0.02em; 
  color: #202020;
  margin-bottom: calc(20/ var(--rem));
} 
.cs-team-modal  .team-details .infos-part  p span{
  color: #CDAB77;
}
.cs-team-modal  .team-details .infos-part h6{
  font-family: 'Barlow'; 
  font-weight: 600;
  font-size: calc(20/ var(--rem));
  line-height: calc(24/ var(--rem));
  letter-spacing: 0.02em; 
  color: #202020;
  text-transform: capitalize;
  padding-top: calc(20/ var(--rem));
  padding-bottom: calc(10/ var(--rem));
  margin: 0;
}  
.cs-team-modal  .team-details .infos-part ul{
  margin: 0;
  padding: 0;
} 

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .Team .list-team { 
    max-width: calc(783 / var(--rem)); 
    margin: 0 0 0 calc(109 / var(--rem)); 
  }
  .cs-team-modal .cs-team-width .modal-body { 
    padding: calc(65 / var(--rem)) calc(52/ var(--rem)) calc(79/ var(--rem)) calc(51/ var(--rem));
}
  .cs-team-modal .team-details .picture-part {
    width: 36.1%;
  }
  .cs-team-modal .team-details .infos-part {
    width: 63.9%;  
    padding-left: calc(25/ var(--rem));
  }
  .cs-team-modal .team-details .infos-part p, .cs-team-modal .team-details .infos-part ul li{
    font-size: calc(14/ var(--rem));
    line-height: calc(19/ var(--rem));
    margin-bottom: calc(19/ var(--rem));
  }
  .cs-team-modal .modal-dialog.cs-team-width {
    max-width: calc(906/ var(--rem));
  }
}
@media(max-height:768px) and (max-width:1100px) and (orientation : landscape) {
  .Team .list-team{
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:600px){
  .Team .list-team {
    max-width: 100%;
    margin: 0 ;
  }
  .Team .list-team{
    padding:  calc(5 / var(--rem))  calc(29 / var(--rem))  calc(100 / var(--rem))  calc(26 / var(--rem));
  }
  .Team .list-team .member-team .member-img {
    width: calc(67/ var(--rem));
  }
  .Team .list-team .member-team .member-details {
    width: calc(100% - calc(67 / var(--rem)));
    padding-left: calc(29 / var(--rem));
  }
  .Team .list-team .member-team .member-details h6 {
    font-size: calc(16 / var(--rem));
    line-height: calc(20 / var(--rem)); 
    margin-bottom: calc(1 / var(--rem));
  }
  .Team .list-team .member-team .member-details p{
    font-size: calc(9 / var(--rem));
    line-height: calc(11 / var(--rem)); 
    margin-bottom: calc(5 / var(--rem)); 
    min-height: calc(22 / var(--rem)) !important;
  } 
  .Team .list-team .member-team .member-details .social a{
    margin-top: 0;
    margin-bottom: calc(9 / var(--rem));
  } 
  .Team .list-team .member-team .member-details .social a svg{
    width: calc(8 / var(--rem));
    height: calc(9 / var(--rem));
  }
  .Team .list-team .member-team .member-details .learn-more p{
    min-height: unset !important;
    margin-bottom: 0;
    margin-top: 0;
  }
  .Team .list-team .member-team .member-img figure{
    margin-bottom: 0;
  }
  .Team .list-team .member-team .learn-more p.more-infos,.Team .list-team .member-team .learn-more p.more-infos+p{
    font-size: calc(8 / var(--rem));
    line-height: calc(9 / var(--rem)); 
    margin-bottom: 0 !important; 
    min-height: unset !important;
  }
  .Team .list-team .member-team{
    padding-bottom:calc(9 / var(--rem)) ;
    margin-bottom: calc(22 / var(--rem));
  }
  .cs-team-modal .modal-dialog.cs-team-width {
    max-width: calc(388/ var(--rem));
  }
  .cs-team-modal .cs-team-width .modal-body {
    padding: calc(48 / var(--rem)) calc(44/ var(--rem)) calc(41/ var(--rem)) calc(43/ var(--rem));
  }
  .cs-team-modal .cs-team-width .modal-body .team-details{
    padding: 0;
  }
  .cs-team-modal .cs-team-width .modal-body .team-details .row{
    margin: 0;
  }
  .cs-team-modal .team-details .picture-part,
  .cs-team-modal .team-details .infos-part{
    width: 100%;
    padding: 0;
  }
  .cs-team-modal .team-details .picture-part figure img{
    margin-bottom: 26px !important;
  }
  .cs-team-modal .team-details .infos-part h4 { 
    font-size: calc(18/ var(--rem));
    line-height: calc(22/ var(--rem)); 
    margin-bottom: calc(7/ var(--rem));
  }
  .cs-team-modal .team-details .infos-part p, .cs-team-modal .team-details .infos-part ul li {
    font-size: calc(9/ var(--rem));
    line-height: calc(12/ var(--rem));
    margin-bottom: calc(12/ var(--rem));
  }
  .cs-team-modal .team-details .infos-part h6 { 
    font-size: calc(10/ var(--rem));
    line-height: calc(12/ var(--rem));  
    margin: 0;
  }
}