.Blogs {
  width: 100%;
  height: auto; 
} 
.Blogs .BgView img { 
  object-position: top;
}
.Blogs .blog-outer{
  max-width: calc(943/ var(--rem));
  margin: 0 auto;
  padding: 0 calc(15/ var(--rem));
} 
/* Cards list */
.Blogs .list-blogs {
  padding: 0;   
}
.Blogs .list-blogs .item-blog {
  height: auto;
  border-bottom: calc(1/ var(--rem)) solid #878787;
  cursor: default;
  padding: calc(39/ var(--rem)) 0 calc(18/ var(--rem));
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
} 
.Blogs .list-blogs .blog-thumbnail{
  width: calc(135/ var(--rem));
}
.Blogs .list-blogs .blog-thumbnail figure{
  width: 100%;
  padding-bottom: 119.3%;
  margin-bottom: 0;
}
.Blogs .list-blogs .blog-short-description{
  width: calc(100% - calc(135/ var(--rem)));
  padding-left: calc(35/ var(--rem));
}
.Blogs .list-blogs .title{
  margin-bottom: calc(36/ var(--rem));
}
.Blogs .list-blogs .title h6 {
  font-family: 'Barlow';
  font-weight: 600;
  font-size: calc(28.17/ var(--rem));
  line-height: calc(34/ var(--rem));
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 0;
  text-transform: capitalize;
}
.Blogs .list-blogs .title p{
  font-family: 'Barlow';
  font-weight: 400;
  font-size: calc(15/ var(--rem));
  line-height: calc(20/ var(--rem));
  letter-spacing: 0.02em;
  color: #878787;
  margin-bottom: 0;
  margin-top: 0;
}
.Blogs .list-blogs .description{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.Blogs .list-blogs .description p.more-infos{
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(15/ var(--rem));
  line-height: calc(22/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #878787;
  width: 75.9%;
  margin-bottom: 0;
}
.Blogs .list-blogs .description p.more-infos a{
    color: #FF3E32 !important;
    text-transform: uppercase;
    font-size: calc(12/ var(--rem));
    font-weight: 600;
}
.Blogs .list-blogs .description p.date{
  font-family: 'Barlow';
  font-weight: 400;
  font-size: calc(15/ var(--rem));
  line-height: calc(28/ var(--rem));
  letter-spacing: 0.02em;
  color: #878787;
  margin-bottom: 0;
  width: 24.1%;
  text-align: right;
  padding-left: calc(10/ var(--rem));
}
.Blogs .blog-outer>p{
  font-family: 'Barlow';
font-style: normal;
font-weight: 400;
font-size: calc(18/ var(--rem));
line-height: calc(32/ var(--rem));
/* identical to box height, or 178% */

letter-spacing: 0.02em;

color: #000000;
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .Blogs .blog-outer {
    max-width: calc(850/ var(--rem)); 
    margin-top: calc(-24/ var(--rem));
  }
  .Blogs .blog-outer>p{
    font-size: calc(16/ var(--rem));
    line-height: calc(29/ var(--rem));
    text-align: center;
    margin-bottom: calc(29/ var(--rem));
  }
  .Blogs .list-blogs .item-blog {  
    padding: calc(34/ var(--rem)) 0 calc(16/ var(--rem)); 
  }
  .Blogs .list-blogs .blog-thumbnail {
    width: calc(123/ var(--rem));
  }
  .Blogs .list-blogs .blog-short-description {
    width: calc(100% - calc(123/ var(--rem)));
    padding-left: calc(31/ var(--rem));
  }
  .Blogs .list-blogs .title h6 { 
    font-size: calc(25/ var(--rem));
    line-height: calc(30/ var(--rem)); 
  }
  .Blogs .list-blogs .title p { 
    font-size: calc(13/ var(--rem));
    line-height: calc(25/ var(--rem)); 
  }
  .Blogs .list-blogs .title {
    margin-bottom: calc(32/ var(--rem));
  }
  .Blogs .list-blogs .description p.more-infos { 
    font-size: calc(13/ var(--rem));
    line-height: calc(20/ var(--rem)); 
  }
  .Blogs .list-blogs .description p.date {
    font-size: calc(13/ var(--rem));
    line-height: calc(25/ var(--rem)); 
  }
  .Blogs .list-blogs .description p.more-infos a { 
    font-size: calc(10/ var(--rem)); 
  } 
  .Blogs .list-blogs { 
    margin-bottom: calc(40/ var(--rem)); 
  }
}
@media(max-width:600px){
  .Blogs .blog-outer { 
    margin-top: calc(-13/ var(--rem));
    padding: 0 calc(47/ var(--rem));
  }
  .Blogs .blog-outer>p {
    font-size: calc(13/ var(--rem));
    line-height: calc(21/ var(--rem));  
    margin: 0 calc(-15/ var(--rem)) calc(-25/ var(--rem));
    text-align: center;
}
.Blogs .list-blogs .item-blog {
  padding: calc(45/ var(--rem)) 0 calc(17/ var(--rem));
  flex-direction: column;
}
.Blogs .list-blogs .blog-thumbnail {
  width: 100%;
}
.Blogs .list-blogs .blog-short-description {
  width: 100%;
  padding-left: 0;
  margin-top:calc(51/ var(--rem)) ;
}
.Blogs .list-blogs .title h6 {
  font-size: calc(29/ var(--rem));
  line-height: calc(35/ var(--rem));
}
.Blogs .list-blogs .title p {
  font-size: calc(15/ var(--rem));
  line-height: calc(29/ var(--rem));
}
.Blogs .list-blogs .title {
  margin-bottom: calc(22/ var(--rem));
}
.Blogs .list-blogs .description{
  flex-direction: column;
}
.Blogs .list-blogs .description>*{
  width: 100% !important;
}
.Blogs .list-blogs .description p.more-infos{
  text-align: justify;
}
.Blogs .list-blogs .description p.more-infos a {
  font-size: calc(12/ var(--rem));
}
.Blogs .list-blogs .description p.date{
  margin-top: calc(17/ var(--rem));
  padding-left: 0;
  text-align: left;
  font-size: calc(15/ var(--rem));
  line-height: calc(29/ var(--rem));
}
.Blogs .list-blogs .description p.more-infos {
  font-size: calc(15.65/ var(--rem));
  line-height: calc(20/ var(--rem));
}
}