.RightPartGraduate p,
.RightPartGraduate .quote p {
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(18 / var(--rem));
  line-height: calc(32 / var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  margin: 0;
}
.RightPartGraduate .right-graduate-inner .imgs {
  width: 100%;
  margin-top: calc(46 / var(--rem));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.RightPartGraduate .right-graduate-inner .imgs .img-left,
.RightPartGraduate .right-graduate-inner .imgs .img-right {
  width: calc(50% - calc(25 / var(--rem)));
  max-width: calc(347 / var(--rem));
}  
.RightPartGraduate .right-graduate-inner .imgs .img-right {
  margin-top: calc(118 / var(--rem));
}
.right-graduate-inner{
  max-width: calc(752 / var(--rem));
  margin: 0 auto;
}
.RightPartGraduate .quote {
  border: calc(1 / var(--rem)) solid #AE100F;  
  padding: calc(17 / var(--rem)) calc(32 / var(--rem)) calc(45 / var(--rem)) calc(23 / var(--rem));
  margin-top: calc(32 / var(--rem));
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .right-graduate-inner {
    max-width: 100%; 
  }
  .RightPartGraduate .right-graduate-inner .imgs{
    flex-wrap: wrap;
  }
  .RightPartGraduate .right-graduate-inner .imgs .img-right {
    width: calc(677 / var(--rem));
    margin-left: auto;
    margin-top: calc(71 / var(--rem));    max-width: unset;
  }
  .RightPartGraduate .right-graduate-inner .imgs .img-left{ 
    width: calc(642 / var(--rem));    max-width: unset;
  }
  .RightPartGraduate .quote {
    border: none;
    padding: 0;
    margin-top: calc(84 / var(--rem));
  }
}
@media(max-width:600px){
  .RightPartGraduate p, .RightPartGraduate .quote p { 
    font-size: calc(13 / var(--rem));
    line-height: calc(20 / var(--rem)); 
  }
  .RightPartGraduate p{
    max-width: calc(352 / var(--rem));
  }
  .RightPartGraduate .right-graduate-inner .imgs .img-left {
    width: calc(279 / var(--rem));
    margin-top: calc(46 / var(--rem));
}
.RightPartGraduate .right-graduate-inner .imgs{
  margin-top: 0;
}
.RightPartGraduate .right-graduate-inner .imgs .img-right {
  width: calc(297 / var(--rem)); 
  margin-top: calc(46 / var(--rem));
}
.RightPartGraduate .quote { 
  margin-top: calc(36 / var(--rem));
}
}