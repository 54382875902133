.videoOuter{
    width: 100%;
} 
.videoOuter .videoPlayer{
    height: 79.45vh;
    max-height: calc(887 / var(--rem));
    position: relative;
}
.videoOuter .videoPlayer video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.video-controls,
.video-controls .video-play-pause{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
 
.video-controls svg {
    position: relative;
    width: 100px;
    height: 100px;
    transform: rotate(-90deg);
} 
.video-controls svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
}
.video-controls svg circle:first-of-type{
    opacity: 0.3;
    transition: 0.3s ease-in-out;
}
.video-controls:hover svg circle:first-of-type{
    opacity: 1;
}
.video-controls svg circle:last-of-type {
    stroke-dasharray: 290px;
    stroke-dashoffset: calc(290px - (290px * var(--percent)) / 100);
    stroke:red; 
}  
.video-controls .video-play-pause{
    display: flex;
    align-items: center;
    justify-content: center;
}  
.video-controls .video-play-pause span{
    width: calc(15 / var(--rem));
    height: calc(15 / var(--rem));
    display: inline-block;
    cursor: pointer;
    position: relative;
} 
.video-controls .video-play-pause span.pause-video::after,
.video-controls .video-play-pause span.pause-video::before {
    content: "";
    position: absolute;
    left: calc(2 / var(--rem));
    top: 0;
    height: 100%;
    background: #fff;
    width: calc(2 / var(--rem));
}
.video-controls .video-play-pause span.pause-video::after{
    left: unset;
    right: calc(2 / var(--rem));
}
.video-controls .video-play-pause span.play-video::after {
    content: "";
    position: absolute;
    border-top: calc(7 / var(--rem)) solid transparent;
    border-bottom: calc(8 / var(--rem)) solid transparent;
    border-left: calc(13 / var(--rem)) solid #fff;
    left: calc(3 / var(--rem));
    top: 0px;
}
.videoOuter h1.title {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: calc(73 / var(--rem));
    line-height: calc(109 / var(--rem));
    display: inline-block;
    bottom: unset;
    pointer-events: none;
    margin-bottom: 0;
    color: #ff3e32;
    font-family: "Anton", "Arial", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: center;
    z-index: 10;
    transition: 0.3s ease-in-out;
}
.videoOuter  .sub-title {
    padding: calc(13 / var(--rem)) 0;
    font-size: calc(38 / var(--rem));
    line-height: calc(64 / var(--rem));
    color: #000;
    letter-spacing: calc(2 / var(--rem));
    font-family: "JuliettaMessie";
    text-align: center;
    margin: 0;
    position: relative;
    z-index: 9; 
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 50px !important;
    transition: 0.3s ease-in-out;
}
.videoOuter .details {
    font-family: 'Poppins', sans-serif;
    line-height: calc(22 / var(--rem));
    font-size: calc(12 / var(--rem));
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 0;
    color: #000;
    width: 100%;
    padding: 0 calc(15 / var(--rem));
    text-transform: uppercase;
    z-index: 10;
    cursor: default;
    letter-spacing: calc(0.2 / var(--rem));     
 
    padding-left: 15px !important;
    max-width: calc(820 / var(--rem));
    padding-right: 15px !important;
    padding-bottom: 50px !important;
}
.videoOuter  .title-outer,
.videoOuter .video-time{
    position: relative;
    z-index: 1;
}
.videoOuter .video-time{
    opacity: 0;
    transition: 0.3s ease-in-out;
}
.videoOuter span.controlsTime {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom:20px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    line-height: calc(22 / var(--rem));
    font-size: calc(20 / var(--rem));
    font-weight: 500;
    text-align: center;
}
span.video-state-text {
    position: absolute !important;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    width: auto !important;
    top: 70px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    line-height: calc(22 / var(--rem));
    font-size: calc(12 / var(--rem));
    font-weight: 500;
    letter-spacing: 1px;
}
#videoPlayercs.fullScreen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    object-fit: contain;
    background-color: #000;
    max-height: unset;
}
.fullscreen-mode,
.fullscreen-mode-off{
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 24px;
    cursor: pointer;
}
#videoPlayercs:not(.fullScreen) .fullscreen-mode-off,
#videoPlayercs.fullScreen .fullscreen-mode,
#videoPlayercs.fullScreen  .title-outer{
    display: none;
}
#videoPlayercs.fullScreen  video{
    object-fit: contain;
}
.fullscreen-mode-off img,
.fullscreen-mode img{
    width: 100%;
}
@media(max-width:1199px){
    .videoOuter h1.title { 
        font-size: calc(52 / var(--rem));
        line-height: calc(79 / var(--rem));
    }
    .videoOuter  .sub-title {     
        padding: calc(10 / var(--rem)) 0 calc(70 / var(--rem));
        font-size: calc(27 / var(--rem));
        line-height: calc(46 / var(--rem));
        letter-spacing: calc(2 / var(--rem)); 
    }
    .videoOuter .details { 
        line-height: calc(23 / var(--rem));
        font-size: calc(14 / var(--rem));
        padding: 0; 
    }
}
@media(max-width:600px){
    .videoOuter h1.title {     
        font-size: calc(31 / var(--rem));
        line-height: calc(46 / var(--rem));
    }
    .videoOuter  .sub-title {     
        padding: calc(10 / var(--rem)) 0 calc(70 / var(--rem));
        font-size: calc(27 / var(--rem));
        line-height: calc(46 / var(--rem));
        letter-spacing: calc(2 / var(--rem)); 
    }
    .videoOuter .details {     
        line-height: calc(20 / var(--rem));
        font-size: calc(11 / var(--rem));
        min-height: calc(160 / var(--rem));
        -webkit-line-clamp: 8; 
    }
    .videoOuter span.controlsTime{
        font-size: calc(18 / var(--rem));
        line-height: calc(22 / var(--rem));
    }
}