.Graduates {
  width: 100%;
  min-height: 100vh;
  position: relative; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(150/ var(--rem)) 0;
}
.graduate-outer{
  max-width: calc(1354/ var(--rem));
  padding: 0 calc(15/ var(--rem));
  margin: 0 auto;
  width: 100%;
} 
.Graduates .col{
  flex: unset;
}
/* Intro part */
.Graduates .intro-part {
  margin: 0;
  width: 44.2%;
}
.Graduates .intro-part h2 {
  max-width: calc(314/ var(--rem)); 
  text-transform: uppercase;
  font-family: 'Anton'; 
  font-weight: 400;
  font-size: calc(72/ var(--rem));
  line-height: calc(109/ var(--rem));
  letter-spacing: 0.02em; 
  color: #000000;
  margin-bottom: calc(17/ var(--rem));
}
.Graduates .intro-part p {
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(15/ var(--rem));
  line-height: calc(20/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  max-width: calc(431/ var(--rem));
}

/* Posts part */
.Graduates .posts-part {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 55.8%;
}
.Graduates .posts-part .posts-circle {
  padding: 0;
  margin: 0;
  position: relative;
  max-width: calc(704/ var(--rem));
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Graduates .posts-part .posts-circle .post {
  cursor: default;
  width: calc(197/ var(--rem));
  height: auto; 
  padding: 0;
  margin: 0;
  transition: all 0.4s ease-in-out;
}
.Graduates .posts-part .posts-circle .post a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  width: 100%;
} 
.Graduates .posts-part .posts-circle> li:nth-of-type(1) {
  margin-left: calc(21/ var(--rem));
  margin-top: calc(34/ var(--rem));
}
.Graduates .posts-part .posts-circle> li:nth-of-type(2) {
  margin-top: calc(20/ var(--rem));
  margin-right: calc(30/ var(--rem));
  margin-left: calc(100/ var(--rem));
}
.Graduates .posts-part .posts-circle> li:nth-of-type(3) {
   margin-top: calc(119/ var(--rem));
}
.Graduates .posts-part .posts-circle> li:nth-of-type(4) {
  margin-top: calc(133/ var(--rem)); 
  margin-left: calc(140/ var(--rem));
}
.Graduates .posts-part .posts-circle> li:nth-of-type(5) {    
  margin-top: calc(3/ var(--rem));
  margin-left: auto;
  margin-right: auto;
} 
.Graduates .posts-part .posts-circle .post a figure{
  width: 100%;
  padding-bottom: 51.3%;
  margin-bottom: calc(12/ var(--rem));
} 
.Graduates .posts-part .posts-circle .post h6 {
  font-family: 'Anton'; 
  font-weight: 400;
  font-size: calc(24/ var(--rem));
  line-height: calc(36/ var(--rem)); 
  letter-spacing: 0.02em; 
  color: #000000;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
}
.Graduates .posts-part .posts-circle .post p {
  font-family: 'Poppins'; 
  font-weight: 600;
  font-size: calc(12/ var(--rem));
  line-height: calc(22/ var(--rem)); 
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase; 
  color: #FF3E32;
  margin: 0;
}

/* -------------- Responsive -------------- */ 
@media(min-width:1729px){ 
  .cs-container{
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:1199px){
  .Graduates { 
    padding: calc(150/ var(--rem))  calc(74/ var(--rem)) ;
  }
  .Graduates .intro-part,
  .Graduates .posts-part{
    width: 100%;
  }
  .Graduates .intro-part h2 {
    max-width: calc(242/ var(--rem)); 
    font-size: calc(55/ var(--rem));
    line-height: calc(84/ var(--rem)); 
    margin-bottom: calc(12/ var(--rem));
  }
  .Graduates .posts-part .posts-circle .post { 
    width: calc(144/ var(--rem)); 
  }
  .Graduates .intro-part p { 
    font-size: calc(18/ var(--rem));
    line-height: calc(32/ var(--rem)); 
    max-width: calc(748/ var(--rem));
  }
  .Graduates .posts-part .posts-circle { 
    max-width: calc(516/ var(--rem)); 
    margin:calc(70/ var(--rem)) auto 0;
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(1) {
    margin-left: calc(15/ var(--rem));
    margin-top: calc(10/ var(--rem));
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(2) {
    margin-top: 0;
    margin-right: calc(22/ var(--rem));
    margin-left: calc(70/ var(--rem));
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(3) {
    margin-top: calc(87/ var(--rem));
  }
  .Graduates .posts-part .posts-circle .post a figure { 
    margin-bottom: calc(8/ var(--rem));
  }
  .Graduates .posts-part .posts-circle .post h6 { 
    font-size: calc(17/ var(--rem));
    line-height: calc(26/ var(--rem)); 
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(4) {
    margin-top: calc(107/ var(--rem));
    margin-left: calc(100/ var(--rem));
  }
  .graduate-outer {
    margin-left: calc(-15/ var(--rem));
}
}
@media(max-width:600px){
  .Graduates {
    padding: calc(151/ var(--rem)) calc(44/ var(--rem)) calc(151/ var(--rem)) calc(28/ var(--rem));
  }
  .graduate-outer{
    padding: 0;
  }
  .Graduates .intro-part h2 { 
    font-size: calc(50/ var(--rem));
    line-height: calc(71/ var(--rem));
    margin-bottom: calc(34/ var(--rem));
  }
  .Graduates .intro-part p {
    font-size: calc(13/ var(--rem));
    line-height: calc(20/ var(--rem)); 
    margin-bottom: calc(20/ var(--rem)) ;
  }
  .Graduates .posts-part .posts-circle{
    margin-top: calc(40/ var(--rem));
  }
  .Graduates .posts-part .posts-circle .post {
    width: calc(94/ var(--rem));
  }
  .Graduates .posts-part .posts-circle .post a figure {
    margin-bottom: calc(5/ var(--rem));
  }
  .Graduates .posts-part .posts-circle .post h6 {
    font-size: calc(11/ var(--rem));
    line-height: calc(17/ var(--rem));
  }
  .Graduates .posts-part .posts-circle .post p { 
    font-size: calc(6/ var(--rem));
    line-height: calc(10/ var(--rem)); 
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(1) {
    margin-left: calc(10/ var(--rem));
    margin-top: calc(6/ var(--rem));
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(3) {
    margin-top: calc(56/ var(--rem));
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(4) {
    margin-top: calc(70/ var(--rem)); 
  }
  .Graduates .posts-part .posts-circle> li:nth-of-type(2) { 
    margin-right: calc(14/ var(--rem)); 
  }
  .Graduates .posts-part{
    padding-left: 6px;
  }
  .graduate-outer {
    margin-left: 0;
  }
}