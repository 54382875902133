.ButtonSimple a,
.ButtonSimple button,
.ButtonSimple button{
  background-color:#CDAB77;
  border: 0.569383px solid #CDAB77;
  padding: calc(14 / var(--rem)) calc(40 / var(--rem));
  text-align: center;
  color: var(--black-color);
  font-size: calc(18 / var(--rem));
  line-height: calc(20 / var(--rem));
  text-align: center;
  font-weight: 600;
  font-family : "Barlow" ; 
  position: relative; 
  border-radius: 0;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
  min-width: calc(272 / var(--rem));
}
.ButtonSimple button:focus,
.ButtonSimple button:active:focus {
  background-color: var(--light-grey-color);
  border-color: var(--light-grey-color);
}
.ButtonSimple:hover a,
.ButtonSimple:hover button {
  border-color: var(--black-color);
  background-color: var(--light-grey-color);
  color: var(--black-color);
  transition: all 0.4s ease-in-out;
}
/* -------------- Responsive -------------- */ 
@media(max-width:600px){
  .ButtonSimple a, .ButtonSimple button, .ButtonSimple button{
    font-size: calc(10 / var(--rem));
    line-height: calc(12 / var(--rem));
    padding: calc(7 / var(--rem)) calc(15 / var(--rem));
    min-width: calc(147 / var(--rem));
  }
}
 