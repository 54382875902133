.BottomPartGraduate {
  width: 100%;  
}
.BottomPartGraduate  .career-part-inner{
  max-width: calc(856 / var(--rem));
  padding: 0 calc(15 / var(--rem));
  width: 100%;
}
/* First part */
.BottomPartGraduate .career-part {
  background-color: var(--black-color);
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  padding: calc(191 / var(--rem)) 0 calc(199 / var(--rem));
}
.BottomPartGraduate .career-part h3 {
  font-family: 'Anton';
  font-weight: 400;
  font-size: calc(70 / var(--rem));
  line-height: calc(83 / var(--rem));
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  max-width: calc(404 / var(--rem));
  margin-bottom: calc(22 / var(--rem));
}
.BottomPartGraduate .career-part p {
  margin-bottom: calc(56 / var(--rem));
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(18 / var(--rem));
  line-height: calc(32 / var(--rem)); 
  letter-spacing: 0.02em; 
  color: #FFFFFF;
}
.career-list-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.BottomPartGraduate .career-part .career-list {
  margin-top: calc(20 / var(--rem));
  width: auto;
}
.BottomPartGraduate .career-part .career-list ul {
  margin: 0;
  padding: 0 0 0 calc(31 / var(--rem));
  border-left: calc(1 / var(--rem)) solid var(--red-color);
  width: auto;
}
.BottomPartGraduate .career-part .career-list ul li {
  font-family: 'Anton'; 
  font-weight: 400;
  font-size: calc(24 / var(--rem));
  line-height: calc(42 / var(--rem)); 
  letter-spacing: 0.02em; 
  color: #FFFDFD;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize; 
}
.BottomPartGraduate .career-part .career-list ul li:first-child {
  margin-top: 0;
}
.BottomPartGraduate .career-part .ButtonSimple {
  margin-top: calc(83 / var(--rem));
}  
/* Second Part */
.BottomPartGraduate .cohort-part {
  background-color: var(--white-color);
  padding: calc(140 / var(--rem)) 0 calc(94 / var(--rem)) 0;
}
.cohort-part-inner{
  max-width: calc(1120 / var(--rem));
  padding: 0 calc(15 / var(--rem));
  width: 100%;
  margin: 0 auto;
}
.BottomPartGraduate .cohort-part h3 {
  font-family: 'Anton'; 
  font-weight: 400;
  font-size: calc(70 / var(--rem));
  line-height: calc(83 / var(--rem)); 
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase; 
  color: #000000; 
  width: 100%;
  text-align: center;
  margin-bottom: calc(140 / var(--rem));
}
.BottomPartGraduate .cohort-part .list-cohort {
  padding: 0;
}
.BottomPartGraduate .cohort-part .list-cohort .item-list { 
  text-transform: capitalize;
  justify-content: space-between;
}
.BottomPartGraduate .cohort-part .list-cohort .item-list div {
  width: 100%;
  font-family: 'Barlow'; 
  font-weight: 400;
  font-size: calc(32 / var(--rem));
  line-height: calc(38 / var(--rem));
  letter-spacing: 0.02em; 
  color: #000000;
  border-bottom: calc(1 / var(--rem)) solid var(--divider-grey-color);
  padding: calc(30 / var(--rem)) 0;
  max-width: calc(50% - calc(107 / var(--rem)));
} 

/* -------------- Responsive -------------- */ 
@media(min-width:1729px){
  .BottomPartGraduate .career-part-inner{
    max-width: 783px;
  }
}
@media(max-width:1199px){
  .BottomPartGraduate .career-part { 
    padding: 0;
  }
  .BottomPartGraduate .career-part-inner{
    max-width: 100%;
    padding: calc(93 / var(--rem))  calc(105 / var(--rem)) calc(105 / var(--rem));
  }
  .BottomPartGraduate .cohort-part{
    padding: 0;
  }
  .cohort-part-inner{
    padding:calc(130 / var(--rem)) calc(60 / var(--rem)) calc(99 / var(--rem));
  }
  .BottomPartGraduate .cohort-part h3{
    line-height:calc(105 / var(--rem)) ;
    margin-bottom: calc(34 / var(--rem));
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div { 
    font-size: calc(26 / var(--rem));
    line-height: calc(31 / var(--rem));  
    padding: calc(24 / var(--rem)) 0;
    max-width: calc(50% - calc(88 / var(--rem)));
  }
}
@media(max-width:600px){
  .BottomPartGraduate .career-part-inner{
    padding: calc(54 / var(--rem)) calc(40 / var(--rem)) calc(80 / var(--rem)) calc(52 / var(--rem));
  }
  .BottomPartGraduate .career-part h3 { 
    font-size: calc(24 / var(--rem));
    line-height: calc(32 / var(--rem)); 
    margin-bottom: calc(17 / var(--rem));
    max-width: calc(164 / var(--rem));
  }
  .BottomPartGraduate .career-part p {
    margin-bottom: calc(16 / var(--rem)); 
    font-size: calc(11 / var(--rem));
    line-height: calc(16 / var(--rem)); 
  }
  .BottomPartGraduate .career-part .career-list ul li { 
    font-size: calc(10 / var(--rem));
    line-height: calc(18 / var(--rem)); 
  }
  .BottomPartGraduate .career-part .ButtonSimple {
    margin-top: calc(43 / var(--rem));
  }
  .cohort-part-inner {
    padding: calc(25 / var(--rem)) calc(23 / var(--rem)) calc(39 / var(--rem));
  }
  .BottomPartGraduate .cohort-part h3 {
    font-size: calc(24 / var(--rem));
    line-height: calc(36 / var(--rem));
    margin-bottom: calc(10 / var(--rem));
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list{
    flex-direction: column;
  }
  .BottomPartGraduate .cohort-part .list-cohort .item-list div{
    width: 100%;
    max-width: 100%;
    padding: calc(10 / var(--rem)) 0 calc(13 / var(--rem));
    font-size: calc(14 / var(--rem));
    line-height: calc(17 / var(--rem));
  }
}