.graduate-details-outer{
  max-width: calc(854 / var(--rem));
  padding:calc(35 / var(--rem)) calc(15 / var(--rem)) calc(210 / var(--rem));
  margin: 0 auto;
}

/* -------------- Responsive -------------- */ 
@media(max-width:1199px){
  .graduate-details-outer {
    max-width: 100%;
    padding: calc(35 / var(--rem)) calc(85 / var(--rem)) calc(50 / var(--rem)); 
  }
}
@media(max-width:600px){
  .graduate-details-outer {
    max-width: 100%;
    padding: calc(5 / var(--rem)) calc(22 / var(--rem)) calc(55 / var(--rem)) calc(27 / var(--rem));
  }
}