.Header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
}
/* Header */
.Header .navbar {
  padding: 0;
  padding-top: calc(40 / var(--rem));
  background-color: transparent;
}
.Header  .navbar-brand{
  padding: 0;
  margin-right:calc(24 / var(--rem)) ;
}
.Header .navbar .container {
  width: 100%;
  max-width: 100%;
  padding: 0 calc(50 / var(--rem));
}
.Header .navbar .container .navbar-collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.Header .navbar-collapse .navbar-text {
  text-align: center;
  cursor: default;
  padding: 0;
}
.Header .navbar-collapse .navbar-text .hamburger-react {
  margin: 0 auto !important;
}
.Header .navbar-collapse .navbar-brand,
.Header .navbar-collapse .navbar-text {
  z-index: 1000;
  margin: 0 !important;
  display: block;
}
.Header .navbar-collapse .navbar-brand .hamburger-react {
  margin: 0 auto !important;
  display: block;
}
.Header .navbar-collapse .navbar-brand img {
  width: calc(122 / var(--rem));
  margin-bottom: calc(15 / var(--rem));
}
.Header .navbar-collapse .navbar-brand svg {
  color: var(--grey-color);
  cursor: pointer;
}
.Header .navbar-collapse .navbar-text .title h6 {
  font-family: "Anton", "Arial", sans-serif;
  color: var(--red-color);
  font-size: calc(14 / var(--rem));
  line-height: calc(21 / var(--rem));
  letter-spacing: calc(4.1 / var(--rem));
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}
.Header .navbar-collapse .navbar-text .title p,
.Header .navbar-collapse span.right-links a  {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: calc(11 / var(--rem));
  text-transform: uppercase;
  color: var(--black-color);
  display: block;
  letter-spacing: 0.2em;
  margin: 0;
  transition: all 0.4s ease-in-out;
  line-height: calc(16 / var(--rem));
  font-weight: 600;
  margin-top:calc( 6 / var(--rem));
}
.Header .navbar-collapse span.right-links{
  display: flex;
}
.Header .navbar-collapse span.right-links a { 
  margin-top: 0;
}
.Header .navbar-collapse span.right-links a+a{
  margin-left:calc( 40 / var(--rem));
}  
.Header .navbar-collapse .navbar-text:nth-child(3) {
  padding: 0;
} 
/* Overlay menu */
.modal-backdrop {
  z-index: 98 !important;
}
.overlay-menu {
  z-index: 99 !important;
  padding: 0 !important;
}
.overlay-menu .modal-dialog {
  cursor: default;
  background-color: var(--white-color);
  margin: 0 !important;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
.overlay-menu .modal-dialog .modal-content {
  border-radius: 0;
  max-height: 100%;
  height: 100%;
}
.overlay-menu .modal-dialog .modal-content .modal-header {
  border: none !important;
}
.overlay-menu .modal-dialog .modal-content .modal-header {
  margin: 0 !important;
}
.overlay-menu .modal-dialog .modal-content .modal-body {
  overflow: hidden;
}
.overlay-menu .modal-dialog .modal-content .modal-body ul {
  padding: 0;
  height: 100%;
  margin: 0 !important;
  padding: calc(80 / var(--rem)) calc(20 / var(--rem)) calc(20 / var(--rem)) calc(20 / var(--rem));
}
.overlay-menu .modal-dialog .modal-content .modal-body ul li{
  text-align: center;
}
.overlay-menu .modal-dialog .modal-content .modal-body ul li+li{
  margin-top: calc(5  / var(--rem));
}
.overlay-menu .modal-dialog .modal-content .modal-body ul li a{
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  font-size: calc(68 / var(--rem));
  line-height: 1.5147;
  font-size: 6.1vh; 
  letter-spacing: 0.02em; 
  color: var(--black-color);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  text-transform: uppercase; 
}
.overlay-menu .modal-dialog .modal-content .modal-body ul a+a{
  margin-top: calc(5 / var(--rem));
}
.overlay-menu .modal-dialog .modal-content .modal-body ul li a.active,
.overlay-menu .modal-dialog .modal-content .modal-body ul li a:hover {
  color: var(--brouwn-color) !important;
} 
.overlay-menu .modal-dialog .modal-content .modal-footer {
  border: none;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  padding: calc(20 / var(--rem)) calc(44 / var(--rem));
}
.overlay-menu .modal-dialog .modal-content .modal-footer p {
  color: var(--grey-color); 
  font-weight: 500;
  font-size: calc(18 / var(--rem));
  font-family: "Barlow";
  line-height: calc(22 / var(--rem));
  margin: 0;
}
.overlay-menu .modal-dialog .modal-content .modal-footer a {
  display: none;
}
.overlay-menu .modal-dialog .modal-content .modal-footer .social-media a {
  display: inline-block;
}
.overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg {
  color: var(--grey-color);
  margin-left: calc(10 / var(--rem));
  margin-right: calc(10 / var(--rem));
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}
.overlay-menu  .modal-dialog  .modal-content  .modal-footer  .social-media  a:hover  svg {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}
 
/* -------------- Responsive -------------- */ 
@media(min-width:1729px){
  
}
@media(max-width:1199px){
  .Header .navbar { 
      padding-top: calc(36/ var(--rem)); 
  }
  .Header .navbar .container { 
    padding: 0 calc(29/ var(--rem));
  }
  .Header .navbar-collapse .navbar-brand img {
    width: calc(115/ var(--rem));
    margin-bottom: calc(26/ var(--rem));
  }
  .Header .navbar-collapse .navbar-text .title h6 {  
    font-size: calc(13/ var(--rem));
    line-height: calc(19/ var(--rem));
    letter-spacing: calc(3/ var(--rem)); 
  }
  .Header .navbar-collapse .navbar-text .title p, .Header .navbar-collapse span.right-links a{ 
    font-size: calc(10/ var(--rem));
    line-height: calc(16/ var(--rem));
    letter-spacing: calc(1.68 / var(--rem));
  } 
  .Header .navbar-collapse span.right-links a+a {
    margin-left: calc( 20 / var(--rem));
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li a { 
    font-size: calc(60 / var(--rem));
    line-height: calc(90 / var(--rem)); 
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li+li {
    margin-top: calc(10 / var(--rem));
  } 
  .overlay-menu .modal-dialog .modal-content .modal-body ul {
    justify-content: flex-start !important; 
    margin-top: calc(170 / var(--rem)) !important;
    padding-top: 0;
    padding-bottom: 0 !important;
    height: calc(100vh - calc(264 / var(--rem)));
    overflow-y: auto;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer { 
    padding: calc(28 / var(--rem)) calc(43 / var(--rem));
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg { 
    margin-left: calc(12 / var(--rem));
    margin-right: calc(12 / var(--rem)); 
  }
  .overlay-menu .modal-body{
    padding: 0;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg {
    width:  calc(16 / var(--rem));
    height:  calc(16 / var(--rem));
}
}
@media(min-width:768px) and (max-width:1199px) and (orientation : landscape) {
  .overlay-menu .modal-dialog .modal-content .modal-body ul li a {
    font-size: calc(57 / var(--rem));
    line-height: calc(75 / var(--rem));
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li+li {
    margin-top: calc(9 / var(--rem));
  }
}
@media(max-width:600px){
  .Header .navbar {
    padding-top: calc(28/ var(--rem));
  }
  .Header .navbar .container {
    padding: 0 calc(14/ var(--rem));
  }
  .Header .navbar-brand>a,
  .Header .navbar-collapse span.right-links{
    display: none;
  }
  .Header .navbar .container .navbar-collapse {
    flex-direction: column-reverse;
    align-items: center;  
  }
  .Header .navbar-collapse .navbar-text .title h6 {
    font-size: calc(10/ var(--rem));
    line-height: calc(15/ var(--rem));
    letter-spacing: calc(2.4/ var(--rem));
  }
  .Header .navbar-collapse .navbar-text .title p  {
    font-size: calc(8/ var(--rem));
    line-height: calc(12/ var(--rem)); 
    letter-spacing: calc(0.04 / var(--rem));
    margin-top:calc(3/ var(--rem)) ;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul { 
    margin-top: calc(183 / var(--rem)) !important; 
    padding-bottom: 0;  
    max-height: calc(100vh - calc(370 / var(--rem))); 
    height: 100%;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li a {
    font-size: calc(19 / var(--rem));
    line-height: calc(23 / var(--rem));
    font-family: 'Barlow';
    font-weight: 600;
  }
  .overlay-menu .modal-dialog .modal-content .modal-body ul li+li {
    margin-top: calc(9 / var(--rem));
  }
  .overlay-menu .modal-dialog .modal-content .modal-body{
    flex: unset;
    padding: 0;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer{
    padding-top: calc(34 / var(--rem));
    justify-content: center;
    flex-direction: column;
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer p { 
    font-size: calc(10 / var(--rem)); 
    line-height: calc(12 / var(--rem)); 
    margin-bottom: calc(24 / var(--rem));
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer .social-media a svg{
    width: calc(14 / var(--rem));
    height: calc(14 / var(--rem));
  }
  /* .overlay-menu .modal-dialog .modal-content .modal-footer>a{
    display: block;
    font-size: calc(8 / var(--rem)); 
    line-height: calc(12 / var(--rem)); 
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: calc(1 / var(--rem));
  } */
  .overlay-menu .modal-dialog .modal-content .modal-footer>a{
    font-family: Barlow;
    font-size: calc(19/var(--rem));
    font-weight: 600;
	  line-height: calc(23/var(--rem));
    display: block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: calc(1 / var(--rem));
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer>a+a{
    margin-top: calc(6 / var(--rem));
  }
  .overlay-menu .modal-dialog .modal-content .modal-footer a+p{
    margin-top: 34px;
  }

}