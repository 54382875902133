* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Barlow", "Arial" sans-serif;
  font-size: 16px;
  color: var(--black-color);
  background-color: var(--white-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  background: rgba(245, 245, 245, 0);
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background: #393939;
  border-radius: 30px;
  transition: 0.2s ease-in-out;
}
::-webkit-scrollbar-thumb:hover {
  background: #000000;
  transition: 0.2s ease-in-out;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #39ff8500;
  background-color: rgba(255, 255, 255, 0);
}
::selection {
  background-color: #262626;
  color: #ffffff;
}
a,
a:hover,
a:visited,
a:active,
a:focus,
a:link {
  color: var(--black-color) !important;
  text-decoration: none !important;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lazy-load-image-background.lazy-load-image-loaded {
  display: initial !important;
}
/* Pagination */
.pagination {
  margin-top:calc( 70 / var(--rem)) !important;
}
.pagination .pagination-item.prev{
  justify-content: flex-end;
  background-image: url(../src/assets/images/icons/prev.svg);
  background-position: left center;
}
.pagination .pagination-item.prev, .pagination .pagination-item.next {
  width: calc(80 / var(--rem));
  height: calc(25 / var(--rem));
  padding: 0;
  border: none !important;
  background-repeat: no-repeat;
  background-size: calc(14 / var(--rem)) calc(25 / var(--rem));
  align-items: center;
}
.pagination .pagination-item.next{
  background-image: url(../src/assets/images/icons/next.svg);
  background-position: right center;
}
.pagination .pagination-item:not(.prev), 
.pagination .pagination-item:not(.next){
  padding: 0;
    border: none !important;
    font-size: calc(16 / var(--rem));
    line-height: calc(14 / var(--rem));
    color: #878787;
    margin: 0 calc(12 / var(--rem));
    align-items: center;
} 
.pagination .pagination-item:not(.prev).active, .pagination .pagination-item:not(.next).active{
  font-weight: 600;
  color:  #CDAB77;
  background-color: transparent !important;
}
.pagination .pagination-item.prev, .pagination .pagination-item.next{
  transition: 0.3s ease-in-out;
}
.pagination .pagination-item.prev:hover, .pagination .pagination-item.next:hover{
  color: #000;
}
@media(max-width:600px){
  .pagination .pagination-item.prev, .pagination .pagination-item.next {
    width: calc(60 / var(--rem));
    height: calc(17 / var(--rem));
  } 
  .pagination .pagination-item:not(.prev), .pagination .pagination-item:not(.next) {
    font-size: calc(7 / var(--rem));
    line-height: calc(11 / var(--rem));
  }
  .pagination .pagination-item.prev, .pagination .pagination-item.next {
    width: calc(32 / var(--rem));
    height: calc(11 / var(--rem)); 
    background-size: calc(6 / var(--rem)) calc(11 / var(--rem)); 
  }
  .pagination .pagination-item:not(.prev), .pagination .pagination-item:not(.next){
    margin: 0  calc(5 / var(--rem));
  }
} 
/* Modal dialog */
.modal-90w {
  width: 90% !important;
  max-width: none !important;
}
.modal .modal-dialog .modal-content .modal-header {
  padding-top: 35px;
  padding-right: 40px;
}
.modal .modal-dialog .modal-content .modal-header button {
  transform: scale(1.3);
  transition: all 0.4s ease-in-out;
}
.modal .modal-dialog .modal-content .modal-header button:hover {
  transform: scale(1.4);
  transition: all 0.4s ease-in-out;
}
.modal-backdrop {
  z-index: 999998 ;
}
.modal.show {
  z-index: 999999 ;
}
.modal{
  padding-top: 5vh  !important;
}
 @media(max-width:600px){
  .modal .modal-dialog .modal-content .modal-header{
    padding: 30px 30px 0 0;
  }
  .modal .modal-dialog .modal-content .modal-header button{
    font-size: 12.4px;
    padding: 0;
  }
 }

/* -------------- Responsive -------------- */ 
/* ******************************************************** */
/* ******************************************************** */
/* ******************************************************** */
/* ******************************************************** */
html{
  font-size: 1vw;
}
:root{
  --RSdes : 17.28;
  --rem : var(--RSdes) * 1rem ;
}
/* @media(min-width:1729px){
  :root{
    --rem : var(--RSdes) * 17.28 px ;
  }
  
} */
@media(min-width:1729px){
  :root{
  --RSdes : 1;
    --rem : var(--RSdes) * 1px ;
  } 
  .modal .modal-dialog .modal-content .modal-header button{
    font-size: 20px;
  }
}
@media(max-width:1199px){
  :root{
    --RSdes : 10.24;
  }
}
@media(min-width:768px) and (max-width:1199px) and (orientation : landscape) {
  :root{
    --RSdes : 12;
  }
} 
@media(max-width:600px){
  :root{
    --RSdes : 4.14;
  }
}
/* cover image */
.cover-img{
  position: relative;
}
.cover-img img{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
/* hover effect */
.Team .list-team .member-team .learn-more p.more-infos,
.Team .list-team .member-team .member-details .social a,
.Blogs .list-blogs .description p.more-infos a,
.Graduates .posts-part .posts-circle .post a h6,
.Graduates .posts-part .posts-circle .post p{
  transition: 0.3s ease-in-out;
}
.Team .list-team .member-team .learn-more p.more-infos{
  cursor: pointer;
}
.Header .navbar-collapse span.right-links a:hover,
.Team .list-team .member-team .member-details .social a:hover,
.Graduates .posts-part .posts-circle .post a:hover h6{
    color: #cdab77 !important;
}
.ButtonSubmit button:hover ,.Partners .container-list .ButtonSimple button:hover{
    background-color: #000 !important;
    color: #cdab77 !important;
}
.Team .list-team .member-team .learn-more p.more-infos:hover,
.Blogs .list-blogs .description p.more-infos a:hover,
.Graduates .posts-part .posts-circle .post a:hover p{
  color: #000 !important;
}
@media(max-width:1199px){
  .valid-feedback,.invalid-feedback { 
    font-size: 11px !important;  
}
}